import { NetdiskFile, NetdiskFileInterface } from './netdisk-file'

export interface FileInfoInterface {
  file?: NetdiskFileInterface
  width?: number | null
  height?: number | null
}

export class FileInfo implements FileInfoInterface {
  file?: NetdiskFile
  width?: number | null
  height?: number | null

  constructor(props: FileInfoInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      file: _file,
      width: _width,
      height: _height,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.file = _file ? new NetdiskFile(_file) : _file
    this.width = _width
    this.height = _height
  }

  static propKeys = ['file', 'width', 'height']
}
