import { Field, FieldInterface } from './field'
import { FieldGroupTagEnum } from './field-group-tag-enum'

export interface FieldGroupInterface {
  /**
   * 学校id
   */
  schoolId: number
  fields?: FieldInterface[] | null
  label: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 所属领域
   */
  domain: string
  /**
   * 群组分类（自定义分组，默认分组）
   */
  tag: FieldGroupTagEnum
  /**
   * 分组名称
   */
  name: string
  /**
   * 包含字段
   */
  fieldNames?: string[] | null
}

export class FieldGroup implements FieldGroupInterface {
  /**
   * 学校id
   */
  schoolId: number
  fields?: Field[] | null
  label: string
  /**
   * 主键 ID
   */
  id: number
  /**
   * 所属领域
   */
  domain: string
  /**
   * 群组分类（自定义分组，默认分组）
   */
  tag: FieldGroupTagEnum
  /**
   * 分组名称
   */
  name: string
  /**
   * 包含字段
   */
  fieldNames?: string[] | null

  constructor(props: FieldGroupInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      fields: _fields,
      label: _label,
      id: _id,
      domain: _domain,
      tag: _tag,
      name: _name,
      fieldNames: _fieldNames,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.fields = _fields ? _fields.map(i => new Field(i)) : _fields
    this.label = _label
    this.id = _id
    this.domain = _domain
    this.tag = _tag
    this.name = _name
    this.fieldNames = _fieldNames
  }

  static propKeys = [
    'schoolId',
    'fields',
    'label',
    'id',
    'domain',
    'tag',
    'name',
    'fieldNames',
  ]
}
