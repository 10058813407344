import { AxiosRequestExtraConfig, isAxiosError } from '@seiue/axios'
import { isReactNative } from '@seiue/util'

import { getPath } from 'packages/route'
import {
  Expand,
  QueryOptionsWithSelect,
  useQueryApi,
  UseQueryApiReturns,
} from 'packages/sdks-next'
import {
  GetGroupByBizQuery,
  /* eslint-disable no-restricted-imports */
  groupApi$findGroupGroupMembers,
  groupApi$getGroupByBiz,
  groupApi$loadGroup,
  groupApi$queryGroupTeam,
  /* eslint-enable no-restricted-imports */
  groupApi$getPermissionsByReflection,
  GroupTypeEnum,
  Group,
} from 'packages/sdks-next/chalk'
import { isChalkUrl } from 'packages/utils/urls'

/**
 * 获取目标用户指定群组（底层概念）中的权限，
 * 比如对于学生档案（基于群组实现）的权限
 *
 * @param args - 参数
 * @param args.rid - 用户 id
 * @param args.type - 群组类型
 * @param args.groupId - 群组 id
 * @param args.bizId - 群组对应业务的业务数据 id
 * @returns 权限列表
 */
export const loadGroupPermissionsForReflection = async ({
  rid,
  type,
  groupId,
  bizId,
}: {
  rid: number
  type: GroupTypeEnum
  groupId?: number
  bizId: string
}) => {
  try {
    // 外面传了就不请求了
    const group = groupId
      ? null
      : await getGroupByBiz(
          {
            type,
            bizId,
          },
          {
            omitNotFound: true,
            omitNotPerm: true,
          },
        )

    const computeGroupId = groupId || group?.data?.id
    if (!computeGroupId) {
      return []
    }

    const { data: permissions } = await groupApi$getPermissionsByReflection.api(
      computeGroupId,
      rid,
    )

    return permissions
  } catch (e) {
    // 接口报 403，也相当于对学生没有档案的访问权限，所以忽略
    if (isAxiosError(e, 403)) {
      return []
    }

    throw e
  }
}

/**
 * 根据关联实体查询其关联的群组
 *
 * @param params - args
 * @param params.type - 群组类型
 * @param params.bizId - 群组对应业务的业务数据 id
 * @param params.query - 查询参数
 * @param options - 配置项
 * @param options.omitNotFound - 是否忽略 404 错误，忽略后将不会显示错误提示并返回 null
 * @param options.omitNotPerm - 是否忽略 403 错误，忽略后将不会显示错误提示并返回 null
 * @returns 群组
 */
export const getGroupByBiz = async (
  params: {
    type: GroupTypeEnum
    bizId: string
    query?: GetGroupByBizQuery
  },
  options?: AxiosRequestExtraConfig & {
    omitNotFound?: boolean
    omitNotPerm?: boolean
  },
) => {
  const { type, bizId, query } = params
  const { omitNotFound, omitNotPerm, ...otherOptions } = options || {}
  const ownerId = getOwnerIdFromRoute()
  try {
    const res = await groupApi$getGroupByBiz.api(
      type,
      bizId,
      {
        ...query,
        ownerId: query?.ownerId ?? ownerId,
      } as GetGroupByBizQuery,
      otherOptions,
    )

    return res
  } catch (e) {
    if (omitNotFound && isAxiosError(e, 404)) {
      return Promise.resolve(null)
    }

    if (omitNotPerm && isAxiosError(e, 403)) {
      return Promise.resolve(null)
    }

    throw e
  }
}

/**
 * 根据关联实体查询其关联的群组 hook
 *
 * @param param0 - 参数
 * @param param0.type - 群组类型
 * @param param0.bizId - 关联实体 ID
 * @param param0.query - 查询参数
 * @param queryOptions - 查询选项
 * @returns 查询结果
 */
export const useGetGroupByBiz: <
  const TQuery extends GetGroupByBizQuery,
  TSelected = Expand<Group, TQuery['expand']>,
>(
  {
    type,
    bizId,
    query,
  }: {
    type: GroupTypeEnum
    bizId: string
    query?: TQuery
  },
  queryOptions?: QueryOptionsWithSelect<
    Expand<Group, TQuery['expand']>,
    TSelected
  > & {
    omitNotFound?: boolean
    omitNotPerm?: boolean
  },
) => UseQueryApiReturns<TSelected> = ({ type, bizId, query }, queryOptions) => {
  return useQueryApi(getGroupByBiz, 'Group.getGroupByBiz', queryOptions, {
    type,
    bizId,
    query,
  })
}

/**
 * 查询某个群组
 *
 * @param args - 参数列表
 * @returns 群组
 */
export const loadGroup = <T extends typeof groupApi$loadGroup.api>(
  ...args: Parameters<T>
) => {
  const [id, loadGroupQuery, options] = args
  const pageParams = getOwnerIdFromRoute()

  return groupApi$loadGroup.api(
    id,
    {
      ...loadGroupQuery,
      ownerId: loadGroupQuery?.ownerId ?? pageParams,
    },
    options,
  )
}

/**
 * 查询某个群组 hook
 *
 * @param param - 参数
 * @param param.id - 群组 ID
 * @param param.query - 查询参数
 * @param queryOptions - 查询选项
 * @returns 群组
 */
export const useLoadGroup: typeof groupApi$loadGroup.useApi = (
  { id, query },
  queryOptions,
) => {
  return useQueryApi(loadGroup, 'Group.loadGroup', queryOptions, id, query)
}

/**
 * 在指定的群组下，查询群组成员的请求配置
 *
 * @param args - 参数列表
 * @returns 请求配置
 */
export const findGroupGroupMembersConfig = <
  T extends typeof groupApi$findGroupGroupMembers.config,
>(
  ...args: Parameters<T>
) => {
  const [groupId, findGroupGroupMembersQuery, options] = args

  const ownerId = getOwnerIdFromRoute()
  return groupApi$findGroupGroupMembers.config(
    groupId,
    {
      ...findGroupGroupMembersQuery,
      ownerId: findGroupGroupMembersQuery?.ownerId ?? ownerId,
    },
    options,
  )
}

/**
 * 在指定的群组下，查询群组成员
 *
 * @param args - 参数列表
 * @returns 群组成员
 */
export const findGroupGroupMembers = <
  T extends typeof groupApi$findGroupGroupMembers.api,
>(
  ...args: Parameters<T>
) => {
  const [groupId, findGroupGroupMembersQuery, options] = args
  const ownerId = getOwnerIdFromRoute()

  return groupApi$findGroupGroupMembers.api(
    groupId,
    {
      ...findGroupGroupMembersQuery,
      ownerId: findGroupGroupMembersQuery?.ownerId ?? ownerId,
    },
    options,
  )
}

/**
 * 在指定的群组下，查询群组成员 hook
 *
 * @param param0 - 参数
 * @param param0.groupId - 群组 ID
 * @param param0.query - 查询参数
 * @param queryOptions - 查询选项
 * @returns 查询结果
 */
export const useFindGroupGroupMembers: typeof groupApi$findGroupGroupMembers.useApi =
  ({ groupId, query }, queryOptions) => {
    return useQueryApi(
      findGroupGroupMembers,
      'Group.findGroupGroupMembers',
      queryOptions,
      groupId,
      query,
    )
  }

/**
 * 查询组内所有小组
 *
 * @param args - 参数列表
 * @returns 小组列表
 */
export const queryGroupTeam = <T extends typeof groupApi$queryGroupTeam.api>(
  ...args: Parameters<T>
) => {
  const [groupId, queryGroupTeamQuery, options] = args
  const ownerId = getOwnerIdFromRoute()

  return groupApi$queryGroupTeam.api(
    groupId,
    {
      ...queryGroupTeamQuery,
      ownerId: queryGroupTeamQuery?.ownerId ?? ownerId,
    },
    options,
  )
}

/**
 * 查询组内所有小组 hook
 *
 * @param param0 - 参数
 * @param param0.groupId - 群组 ID
 * @param param0.query - 查询参数
 * @param queryOptions - 查询选项
 * @returns 查询结果
 */
export const useQueryGroupTeam: typeof groupApi$queryGroupTeam.useApi = (
  { groupId, query },
  queryOptions,
) => {
  return useQueryApi(
    queryGroupTeam,
    'Group.queryGroupTeam',
    queryOptions,
    groupId,
    query,
  )
}

/**
 * 从路由中获取 ownerId 参数
 *
 * 在下面路由中，指定默认的 ownerId 参数，提供给后端用于权限判断
 * ClassesDetailForReflection - 学生档案 => 群组 => 课程班 => 课程班详情
 *
 * @returns ownerId 参数
 */
export const getOwnerIdFromRoute = () => {
  if (isReactNative) return undefined

  const { location } = window
  if (!isChalkUrl(location.href)) return undefined

  const parseProfilePathParams = (path: string) => {
    const regexp = new RegExp(path.replace(/-1/g, '([^/]+)'))
    const matchResult = location.pathname.match(regexp)

    if (matchResult) {
      return matchResult.slice(1)
    }

    return null
  }

  const pathConfigs = [
    {
      // 学生档案 => 群组 => 课程班 => 课程班详情
      path: getPath('ClassesDetailForReflection', {
        rid: -1,
        classId: -1,
      }),
      // ownerId 动态参数的位置
      index: 1,
    },
  ]

  // 遍历路径配置，提取参数
  for (const { path, index } of pathConfigs) {
    const params = parseProfilePathParams(path)

    if (params?.[index]) {
      return Number(params[index])
    }
  }

  return undefined
}
