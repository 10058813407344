/**
 * @file 用户 - 路由
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'

import { guardianRoutes } from './guardian'
import { menuRoutes } from './menu'
import { staffRoutes } from './staff'
import { studentRoutes } from './student'
import { teacherRoutes } from './teacher'

const currentRoutes = [
  {
    name: 'Me',
    path: 'users/current/account',
    getTitle: () => $t('个人设置'),
    component: lazy(() =>
      import('../pages/me').then(m => ({
        default: m.Settings,
      })),
    ),
    subRoutes: [
      {
        name: 'UserInfo',
        path: 'info',
        getTitle: () => $t('个人资料'),
        component: lazy(() =>
          import('../pages/me/Info').then(m => ({
            default: m.UserInfo,
          })),
        ),
      },
      {
        name: 'UserSettings',
        path: 'settings',
        getTitle: () => $t('账号设置'),
        component: lazy(() =>
          import('../pages/me/Account').then(m => ({
            default: m.Account,
          })),
        ),
      },
    ],
  },
  {
    name: 'MeEdit',
    path: 'users/current/account/edit',
    getTitle: () => $t('个人资料编辑'),
    component: lazy(() =>
      import('../pages/me/Info').then(m => ({
        default: m.UserInfo,
      })),
    ),
  },
]

export const routes = [
  ...menuRoutes,
  ...studentRoutes,
  ...teacherRoutes,
  ...guardianRoutes,
  ...staffRoutes,
  ...currentRoutes,
]
