import { lazy } from 'react'

import { PluginEnum } from 'packages/feature-utils/plugins'
import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

import { RouteMenus } from '@/router'

export const userRoutes: RouteConfig[] = [
  {
    path: 'user/report-center',
    name: 'PluginReportCenter',
    module: [SchoolPluginsEnum.ReportCenter],
    requireModules: [PluginEnum.ReportCenter],
    getTitle: () => $t('报表中心'),
    component: lazy(() =>
      Promise.resolve({
        default: RouteMenus,
      }),
    ),
    subRoutes: [
      {
        path: 'report-manage',
        name: 'ReportManage',
        getTitle: () => $t('我管理的报表'),
        component: lazy(() =>
          import('../pages/user/report/MangedList').then(m => ({
            default: m.List,
          })),
        ),
      },
      {
        path: 'report-manage/:id/detail',
        name: 'AdminReportDetail',
        getTitle: () => $t('报表详情'),
        component: lazy(() =>
          import('../pages/user/report/Detail').then(m => ({
            default: m.Detail,
          })),
        ),
      },
      {
        path: 'report-published',
        name: 'ReportPublished',
        getTitle: () => $t('已发布报表'),
        component: lazy(() =>
          Promise.resolve({
            default: RouteMenus,
          }),
        ),
        subRoutes: [
          {
            path: 'manged',
            name: 'Manged',
            getTitle: () => $t('我管理的报表'),
            component: lazy(() =>
              import('../pages/user/reportPublish/MangedList').then(m => ({
                default: m.List,
              })),
            ),
          },
          {
            path: 'received',
            name: 'Received',
            getTitle: () => $t('我接收的'),
            component: lazy(() =>
              import('../pages/user/reportPublish/Received').then(m => ({
                default: m.ReportPublishReceived,
              })),
            ),
            subRoutes: [
              {
                path: 'mine',
                getTitle: () => $t('发送我的'),
                component: lazy(() =>
                  import(
                    '../pages/user/reportPublish/Received/ReceivedList'
                  ).then(m => ({
                    default: m.ReceivedList,
                  })),
                ),
              },
              {
                path: 'cc',
                getTitle: () => $t('抄送我的'),
                component: lazy(() =>
                  import('../pages/user/reportPublish/Received/CcList').then(
                    m => ({
                      default: m.CcList,
                    }),
                  ),
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: 'user/report-center/report-published/publishes',
    getTitle: () => $t('我管理的发布'),
    component: lazy(() =>
      import('../pages/user/reportPublish/Received/ManagedList').then(m => ({
        default: m.ManagedList,
      })),
    ),
  },
  {
    path: 'user/report-center/report-published/publishes/:publishId/results',
    name: 'PluginReportCenter.ReportPublished.Publishes.ResultList',
    getTitle: () => $t('报表发布范围'),
    component: lazy(() =>
      import('../pages/user/reportPublish/Received/ResultList').then(m => ({
        default: m.ResultList,
      })),
    ),
  },
  {
    path: 'user/report-center/report-published/received/:publishId/results',
    name: 'PluginReportCenter.ReportPublished.ResultList',
    getTitle: () => $t('报表发布范围'),
    component: lazy(() =>
      import('../pages/user/reportPublish/Received/ResultList').then(m => ({
        default: m.ResultList,
      })),
    ),
  },
  {
    path: 'user/report-center/report-published/publishes/:id/detail',
    name: 'PluginReportCenter.Publishes.Detail',
    getTitle: () => $t('报表详情'),
    component: lazy(() =>
      import('../pages/user/reportPublish/ReportDetail').then(m => ({
        default: m.ReportDetail,
      })),
    ),
  },
  {
    path: 'user/report-center/report-published/received/:id/detail',
    name: 'PluginReportCenter.Received.Detail',
    getTitle: () => $t('报表详情'),
    component: lazy(() =>
      import('../pages/user/reportPublish/ReportDetail').then(m => ({
        default: m.ReportDetail,
      })),
    ),
  },
]
