import { ExamScoreDisplayTypeEnum } from './exam-score-display-type-enum'
import { ExamPublishCcRoleEnum } from './exam-publish-cc-role-enum'
import {
  GzzxExamScoreAnalysisTranscriptRule,
  GzzxExamScoreAnalysisTranscriptRuleInterface,
} from './gzzx-exam-score-analysis-transcript-rule'
import {
  GzzxExamScoreAnalysisSportsTestingRule,
  GzzxExamScoreAnalysisSportsTestingRuleInterface,
} from './gzzx-exam-score-analysis-sports-testing-rule'

export interface ExamCustomDataInterface {
  /**
   * 科目匹配时对应字段，前端显示使用
   */
  subjectMatchField?: string
  /**
   * 学生档案与成绩单成绩形式（同时影响学生成绩单和学生档案页的学科考试成绩）
   *  - score
   *  - level_score
   *  - score_and_level_score
   */
  scoreDisplay?: ExamScoreDisplayTypeEnum | null
  /**
   * 考试发布抄送的角色（同时影响档案和群组显示考试 Tab）
   */
  publishCcRoles?: ExamPublishCcRoleEnum[]
  /**
   * 成绩单规则
   */
  transcript?: GzzxExamScoreAnalysisTranscriptRuleInterface[]
  /**
   * 体育测试规则
   */
  sportsTesting?: GzzxExamScoreAnalysisSportsTestingRuleInterface[] | null
  /**
   * 成绩折线图
   */
  lineChart?: boolean
}

export class ExamCustomData implements ExamCustomDataInterface {
  /**
   * 科目匹配时对应字段，前端显示使用
   */
  subjectMatchField?: string
  /**
   * 学生档案与成绩单成绩形式（同时影响学生成绩单和学生档案页的学科考试成绩）
   *  - score
   *  - level_score
   *  - score_and_level_score
   */
  scoreDisplay?: ExamScoreDisplayTypeEnum | null
  /**
   * 考试发布抄送的角色（同时影响档案和群组显示考试 Tab）
   */
  publishCcRoles?: ExamPublishCcRoleEnum[]
  /**
   * 成绩单规则
   */
  transcript?: GzzxExamScoreAnalysisTranscriptRule[]
  /**
   * 体育测试规则
   */
  sportsTesting?: GzzxExamScoreAnalysisSportsTestingRule[] | null
  /**
   * 成绩折线图
   */
  lineChart?: boolean

  constructor(props: ExamCustomDataInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      subjectMatchField: _subjectMatchField,
      scoreDisplay: _scoreDisplay,
      publishCcRoles: _publishCcRoles,
      transcript: _transcript,
      sportsTesting: _sportsTesting,
      lineChart: _lineChart,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.subjectMatchField = _subjectMatchField
    this.scoreDisplay = _scoreDisplay
    this.publishCcRoles = _publishCcRoles
    this.transcript = _transcript
      ? _transcript.map(i => new GzzxExamScoreAnalysisTranscriptRule(i))
      : _transcript
    this.sportsTesting = _sportsTesting
      ? _sportsTesting.map(i => new GzzxExamScoreAnalysisSportsTestingRule(i))
      : _sportsTesting
    this.lineChart = _lineChart
  }

  static propKeys = [
    'subjectMatchField',
    'scoreDisplay',
    'publishCcRoles',
    'transcript',
    'sportsTesting',
    'lineChart',
  ]
}
