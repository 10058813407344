/**
 * @file venue menus
 */
import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import { isMenuOpenedAtom } from 'packages/feature-utils/menus'
import {
  findEnabledPluginsAtom,
  getPluginIcon,
  hasEnabledPluginAtom,
  hasManageableParentPluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { MenuType } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'
import { venueApi$queryAdminVenues } from 'packages/sdks-next/scms'
import { MenuItemUnion } from 'packages/web-layout/types'

export const adminVenueMenu = atom(get => {
  if (!get(hasManageableParentPluginAtom(PluginEnum.Venue))) {
    return []
  }

  const enabledPlugin = get(findEnabledPluginsAtom(PluginEnum.Venue))?.[0]
  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  return [
    {
      prefix: [PluginCategoryEnum.后勤管理],
      name: SchoolPluginsEnum.Venue,
      label: $t('场地预约管理'),
      path: '/admin/venues',
      icon: 'VenueReservation' as const,
      shortcutIcon,
      sort: 800,
      subMenus: [
        {
          label: $t('全部场地'),
          path: '/admin/venues/list',
          icon: 'VenueReservation' as const,
        },
        {
          label: $t('预约记录'),
          path: '/admin/venues/orders',
          icon: 'Log' as const,
        },
        {
          label: $t('违规统计'),
          path: '/admin/venues/violation-stats',
          icon: 'Statistics' as const,
        },
        {
          label: $t('设置'),
          path: '/admin/venues/settings',
          icon: 'Setting' as const,
        },
      ],
    },
  ]
})

export const userVenueMenu = loadable(
  atom(async get => {
    if (!get(hasEnabledPluginAtom(PluginEnum.Venue))) {
      return []
    }

    const menuOpened = get(isMenuOpenedAtom([PluginEnum.Venue]))

    const subMenus: MenuItemUnion[] = [
      {
        label: $t('场地列表'),
        path: '/venues/venue-list',
        icon: 'VenueReservation' as const,
      },
      {
        label: $t('我的预约'),
        path: '/venues/my-orders',
        icon: 'SchoolCalendar' as const,
      },
    ]

    const baseMenu = {
      name: SchoolPluginsEnum.Venue,
      label: $t('场地预约'),
      type: MenuType.Apps,
      subMenus,
    }

    if (menuOpened) {
      const { data: venues } = await venueApi$queryAdminVenues.api({
        perPage: 1,
      })

      if (venues.length) {
        subMenus.push(
          ...[
            {
              label: $t('我管理的'),
              path: '/venues/managed',
              icon: 'Log' as const,
            },
            {
              label: $t('预约审批'),
              path: '/venues/my-approvals',
              icon: 'Approval' as const,
            },
          ],
        )
      }
    }

    if (get(hasManageableParentPluginAtom(SchoolPluginsEnum.Venue))) {
      subMenus.push({
        label: $t('前往场地管理'),
        path: '/admin/venues',
        icon: 'Backstage' as const,
      })
    }

    return [baseMenu]
  }),
)
