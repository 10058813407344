import {
  getReflectionAdminClassesLabels,
  getReflectionGenderLabel,
  getReflectionGraduatesInAndGradeLabel,
} from 'packages/features/reflections/utils/views'

import {
  ReflectionWithUser as Base,
  ReflectionWithUserInterface,
} from '../reflection-with-user'

export type { ReflectionWithUserInterface }

/**
 * 带用户的身份
 */
export class ReflectionWithUser extends Base {
  /**
   * 带年级的行政班列表
   *
   * @returns 行政班列表
   */
  get adminClassesLabels() {
    return getReflectionAdminClassesLabels(this)
  }

  /**
   * 带年级的届别
   *
   * @returns 届别
   */
  get graduatesInAndGradeLabel() {
    return getReflectionGraduatesInAndGradeLabel(this)
  }

  /**
   * 性别 label
   *
   * @returns 性别 label
   */
  get genderLabel() {
    return getReflectionGenderLabel(this)
  }
}
