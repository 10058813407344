/**
 * 基础模型类型
 */
export type BasicModel<T = any> = {
  /**
   * ID
   */
  id: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
} & T

export enum AppNameEnum {
  // 学术成果
  AcademicAchievement = 'academic-achievement',
  // 教师赋分
  GzAcademicCredit = 'gz-academic-credit',
  // 作业调查
  SzHomeworkSurvey = 'sz-homework-survey',
  // 学段评估
  BnsAssessmentReport = 'bns-assessment-report',
  // 社团
  Club = 'club',
  // 公文流转
  GzDocumentFlow = 'gongwenliuzhuan1',
}
