/**
 * @file slots
 */

import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const tabSlot: RouteConfig[] = [
  {
    path: 'cgr-grades-reports',
    getTitle: () => $t('报告单'),
    name: 'CgrGradesReports',
    component: lazy(() =>
      import('../components/ProfileGradesReport').then(mod => ({
        default: mod.ProfileGradesReport,
      })),
    ),
    /**
     * 142, 深圳湾线上
     * 312, 深圳湾测试
     * 361, 管理平台学校-报表中心测试用, 表现为深圳湾
     * 303, 超人测试学校线上，用于深圳湾线上回归
     *
     * 403, 协和培明线上
     * 505, 协和培明测试
     * 194, B 组测试线上，用于协和培明线上回归
     *
     * 282, 深圳中学线上
     * 83, 深圳中学测试
     * 491, 开发用学校，表现为深圳中学
     */
    requireSchools: [142, 312, 403, 505, 361, 194, 303, 282, 83, 491],
    subRoutes: [
      {
        path: 'show-grade',
        getTitle: () => $t('分项报告单'),
        component: lazy(() =>
          import(
            '../pages/reports/student/StudentGradeReport/SzwStudentGradeReport'
          ).then(m => ({
            default: m.SzwStudentGradeReport,
          })),
        ),
        requireSchools: [142, 312, 361, 303],
      },
      {
        path: 'show-xhpm-grade',
        getTitle: () => $t('成绩报告单'),
        component: lazy(() =>
          import(
            '../pages/reports/student/StudentGradeReport/XHPMStudentGradeReport'
          ).then(m => ({
            default: m.XHPMStudentGradeReport,
          })),
        ),
        requireSchools: [403, 505, 194],
      },
      {
        path: 'show-szcz-grade',
        getTitle: () => $t('考试成绩单'),
        component: lazy(() =>
          import(
            '../pages/reports/student/StudentGradeReport/SzCzStudentGradeReport'
          ).then(m => ({
            default: m.SzCzStudentGradeReport,
          })),
        ),
        requireSchools: [282, 83, 491],
      },
      {
        path: 'show-semester',
        getTitle: () => $t('学期报告单'),
        component: lazy(() =>
          import(
            '../pages/reports/student/StudentSemesterReport/SzwStudentSemesterReport'
          ).then(m => ({
            default: m.SzwStudentSemesterReport,
          })),
        ),
        requireSchools: [142, 312, 361, 303],
      },

      {
        path: 'show-szcz-semester',
        getTitle: () => $t('学期报告单'),
        component: lazy(() =>
          import(
            '../pages/reports/student/StudentSemesterReport/SzCzStudentSemesterReport'
          ).then(m => ({
            default: m.SzCzStudentSemesterReport,
          })),
        ),
        requireSchools: [282, 83, 491],
      },
      {
        path: 'show-abroad-report',
        getTitle: () => $t('出国成绩单'),
        component: lazy(() =>
          import('../pages/reports/student/SzczGoAbroad').then(m => ({
            default: m.SzczGoAbroad,
          })),
        ),
        requireSchools: [282],
      },
      {
        path: 'show-resit-report',
        getTitle: () => $t('补考/重考成绩单'),
        component: lazy(() =>
          import('../pages/reports/student/SzczResitReport').then(m => ({
            default: m.SzczResitReport,
          })),
        ),
        requireSchools: [282],
      },
    ],
  },
]
