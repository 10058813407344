/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  ReflectionWithUser,
  ReflectionWithUserInterface,
} from './extends/reflection-with-user'
import type { LoadStudentQuery } from './load-student-query'

const buildConfig = createConfigBuilder('chalk')

export const reflectionApi$loadStudent = {
  config(
    id: number,
    loadStudentQuery?: LoadStudentQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/reflection/students/{id}',
      {
        id,
      },
      loadStudentQuery,
    )
  },

  /**
   * @summary Class LoadStudentApi
   * @param id
   * @param [loadStudentQuery]
   * @param [reflectionQuery.expand]
   * @param [options]
   * @return AxiosResponsePromise<ReflectionWithUser>
   */

  api<const TQuery extends LoadStudentQuery>(
    id: number,
    loadStudentQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<ReflectionWithUser, TQuery['expand']>> {
    return axios(
      reflectionApi$loadStudent.config(id, loadStudentQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new ReflectionWithUser(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends LoadStudentQuery,
    TSelected = Expand<ReflectionWithUser, TQuery['expand']>,
  >(
    {
      id,
      query,
    }: {
      id: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<ReflectionWithUser, TQuery['expand']>,
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<ReflectionWithUser, TQuery['expand']>, TSelected>(
      reflectionApi$loadStudent.api,
      'Reflection.loadStudent',
      queryOptions,
      id,
      query,
    )
  },
}

// @ts-ignore
reflectionApi$loadStudent.api._name_ = `Reflection.loadStudent`
