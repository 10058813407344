/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { RoleEnum } from './role-enum'
import {
  ReflectionWithProfile,
  ReflectionWithProfileInterface,
} from './reflection-with-profile'
import type { LoadReflectionProfileQuery } from './load-reflection-profile-query'

const buildConfig = createConfigBuilder('chalk')

export const reflectionProfileApi$loadReflectionProfile = {
  config(
    role: RoleEnum,
    policy: string,
    id: number,
    loadReflectionProfileQuery?: LoadReflectionProfileQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/reflection_profile/profiles/{id}',
      {
        role,
        policy,
        id,
      },
      loadReflectionProfileQuery,
    )
  },

  /**
   * @summary 查询人事/学籍管理的信息
   * @param role role
   * @param policy 访问策略
   * personnel-profile 人事管理
   * student-status-profile - 学籍管理
   * @param id
   * @param [loadReflectionProfileQuery]
   * @param [reflectionProfileQuery.expand] guardians
   * reflection_profile
   * @param [options]
   * @return AxiosResponsePromise<ReflectionWithProfile>
   */

  api<const TQuery extends LoadReflectionProfileQuery>(
    role: RoleEnum,
    policy: string,
    id: number,
    loadReflectionProfileQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<ReflectionWithProfile, TQuery['expand']>> {
    return axios(
      reflectionProfileApi$loadReflectionProfile.config(
        role,
        policy,
        id,
        loadReflectionProfileQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new ReflectionWithProfile(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends LoadReflectionProfileQuery,
    TSelected = Expand<ReflectionWithProfile, TQuery['expand']>,
  >(
    {
      role,
      policy,
      id,
      query,
    }: {
      role: RoleEnum
      policy: string
      id: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<ReflectionWithProfile, TQuery['expand']>,
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<
      Expand<ReflectionWithProfile, TQuery['expand']>,
      TSelected
    >(
      reflectionProfileApi$loadReflectionProfile.api,
      'ReflectionProfile.loadReflectionProfile',
      queryOptions,
      role,
      policy,
      id,
      query,
    )
  },
}

// @ts-ignore
reflectionProfileApi$loadReflectionProfile.api._name_ = `ReflectionProfile.loadReflectionProfile`
