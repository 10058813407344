/**
 * @file 待办相关类型声明
 */
import React, { FC, LazyExoticComponent } from 'react'

import { Expand } from 'packages/sdks-next'
import { Todo as OriginTodo } from 'packages/sdks-next/chalk'

import { TodoTypeEnum, SpecTodoRelated } from './todo-type-enum'

export * from './todo-domain-enum'
export * from './todo-type-enum'

export type TodoCustomRenderComponent = FC<{
  todo: TodoType
  onClick?: () => void
  tag?: {
    text: string
    color: string
    backgroundColor: string
  }

  /**
   * @see apps/chalk/src/components/EventCard/blocks/Time.tsx
   */
  time?: {
    time?: string | null
    timeEnd?: string | null
    nullText?: string | [string, string]
    timeType?: 'start' | 'special' | 'end'
    disableStatus?: boolean
  }
  location?: string
}>

export interface TodoType extends Expand<OriginTodo, ['related']> {
  /*
   * 待办相关的业务数据，是显示和处理待办的关键
   * 此处声明为 any，由 SpecTodoRelated 补充
   */
  related: any
  /**
   * 待办本身的业务相关 ID 信息，待办类型等核心数据
   * 但因为返回数据并不明确，所以设定为 any
   */
  labels?: any

  /**
   * 自定义渲染
   */
  _CustomRender?: TodoCustomRenderComponent
}

export interface TodoPatch extends Partial<TodoType> {
  id: number
}

export type TodoRender<T extends TodoType> = React.FC<{
  // 后端返回的待办数据
  todo: T
  // 是否在首待执行待办流中（会有一些特别的交互）
  inFlow: boolean
  // 是否简化显示，隐藏大部分信息和操作交互（仅剩标题，时间和点击跳转），适用于一些小卡片
  simplify?: boolean
}>

export type TodoRelated<T> = T | null

export interface TodoExecution {
  type: TodoTypeEnum
  Render: LazyExoticComponent<TodoRender<TodoType>>
}

export interface RegisterTodos {
  (todos: TodoExecution | TodoExecution[]): void
}

export interface SpecTodoType<T> extends TodoType {
  related: TodoRelated<T>
}

export type TodoRenders = {
  [K in keyof SpecTodoRelated]: TodoRender<SpecTodoType<SpecTodoRelated[K]>>
}
