/**
 * @file 补考 routes
 */

import { lazy } from 'react'

import { SchoolPluginsEnum } from 'packages/features/schools/types'
import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const adminRoutes: RouteConfig[] = [
  {
    name: 'AdminPluginResit',
    path: 'admin/plugin/resit',
    getTitle: () => $t('补考管理'),
    module: [PluginCategoryEnum.教务教学, SchoolPluginsEnum.ExamResit],
    component: lazy(() =>
      import('./pages/admin/resit/List').then(m => ({
        default: m.AdminPluginResit,
      })),
    ),
  },
  {
    name: 'AdminResitDetail',
    path: 'admin/plugin/resit/detail/:id',
    getTitle: () => $t('补考详情'),
    component: lazy(() =>
      import('./pages/admin/resit/Detail').then(m => ({
        default: m.Input,
      })),
    ),
    subRoutes: [
      {
        path: 'gradeInput',
        getTitle: () => $t('补考成绩录入'),
        component: lazy(() =>
          import('./pages/admin/resit/Detail/ConditionDetail/GradeInput').then(
            m => ({
              default: m.GradeInput,
            }),
          ),
        ),
      },
      {
        path: 'list',
        getTitle: () => $t('补考名单'),
        component: lazy(() =>
          import('./pages/admin/resit/Detail/ConditionDetail/List').then(m => ({
            default: m.List,
          })),
        ),
      },
    ],
  },
]
