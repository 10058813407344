import React from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { RoleEnum, WidgetTypeEnum } from 'packages/sdks-next/chalk'

import {
  RegisterWidget,
  WidgetKeyEnum,
  WidgetLayoutSize,
} from '@/features/widgets/types'

export const widgets: RegisterWidget[] = [
  {
    key: WidgetKeyEnum.CopilotHomeBar,
    label: () => $t('AI 助手'),
    icon: 'AiLogo',
    type: WidgetTypeEnum.PluginBiz,
    pluginName: PluginNameEnum.Copilot,
    enableRoles: [
      RoleEnum.Student,
      RoleEnum.Teacher,
      RoleEnum.Guardian,
      RoleEnum.Staff,
    ],
    sizes: [WidgetLayoutSize.Large],
    layout: {
      rows: 1,
      isDraggable: true,
    },
    PreviewComponent: React.lazy(() =>
      import('./CopilotHomeBar/CopilotHomeBarPreview').then(m => ({
        default: m.CopilotHomeBarPreview,
      })),
    ),
    RealComponent: React.lazy(() =>
      import('./CopilotHomeBar/CopilotHomeBar').then(m => ({
        default: m.CopilotHomeBar,
      })),
    ),
    personalization: true,
    defaultDisplay: true,
    defaultSort: 1,
  },
]
