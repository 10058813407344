import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import {
  hasManageableParentPluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'
import { MenuItemUnion, MenuType } from 'packages/web-layout/types'

/**
 * Copilot 后台菜单
 */
export const adminCopilotMenu = loadable(
  atom<MenuItemUnion[]>(get => {
    if (
      !get(hasManageableParentPluginAtom(PluginEnum.Copilot)) &&
      !get(hasManageableParentPluginAtom(PluginNameEnum.CopilotDemo))
    ) {
      return []
    }

    const subMenus: MenuItemUnion[] = []

    subMenus.push({
      label: $t('数据统计'),
      icon: 'Chart',
      path: '/admin/plugin/copilot/stats',
    })

    subMenus.push({
      label: $t('知识库'),
      icon: 'BookOpen',
      path: '/admin/plugin/copilot/knowledge-bases',
    })

    return [
      {
        prefix: [PluginCategoryEnum.通用工具],
        name: PluginNameEnum.Copilot,
        label: $t('AI 助手管理'),
        type: MenuType.AdminApps,
        sort: 900,
        subMenus,
      },
    ]
  }),
)
