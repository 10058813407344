import {
  UploadLimitSetting,
  UploadLimitSettingInterface,
} from './upload-limit-setting'

export interface NetdiskOwnerSettingInterface {
  /**
   * 限制类型 none 不限制 part 部分限制
   */
  uploadLimit?: string
  uploadLimitSetting?: UploadLimitSettingInterface
}

export class NetdiskOwnerSetting implements NetdiskOwnerSettingInterface {
  /**
   * 限制类型 none 不限制 part 部分限制
   */
  uploadLimit?: string
  uploadLimitSetting?: UploadLimitSetting

  constructor(props: NetdiskOwnerSettingInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      uploadLimit: _uploadLimit,
      uploadLimitSetting: _uploadLimitSetting,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.uploadLimit = _uploadLimit
    this.uploadLimitSetting = _uploadLimitSetting
      ? new UploadLimitSetting(_uploadLimitSetting)
      : _uploadLimitSetting
  }

  static propKeys = ['uploadLimit', 'uploadLimitSetting']
}
