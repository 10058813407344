import { isString } from '@seiue/util'

import { Query } from 'packages/hooks'
import { parseExpand, Path } from 'packages/sdks-next'

/**
 * 值处理器，参数和返回值类型相同
 */
export type Processor<T = any> = (val: T) => T

/**
 * 直接返回参数，啥也不做的 processor
 *
 * @param v - 参数
 * @returns 直接返回参数
 */
export const NoOpProcessor = <T = any>(v: T) => v

/**
 * 合并多个值处理器，返回一个新的处理器
 * 传入的范型为处理器默认的返回值类型
 *
 * @param processors - 值处理器数组
 * @returns 合并后的处理器
 */
export const combineProcessorsWithDefaultType = <D>(
  processors: Array<Processor>,
) => {
  return <T = D>(val: T) =>
    processors.reduce((prev, processor) => processor(prev), val)
}

/**
 * 合并多个值处理器，返回一个新的处理器
 * 传入的范型为处理器指定返回值类型
 *
 * @param processors - 值处理器数组
 * @returns 合并后的处理器
 */
export const combineProcessorsWithSpecificType = <T>(
  processors: Array<Processor<T>>,
) => {
  return (val: T) =>
    processors.reduce((prev, processor) => processor(prev), val)
}

/**
 * 给 query 附上额外的 expand 参数
 *
 * @param extraExpands - 额外 expand 参数
 * @param prefix - 前缀
 * @returns changed query
 */
export const getExtraQueryProcessor = (
  extraExpands: string[],
  prefix?: string,
) => {
  const _prefix = prefix ? `${prefix}.` : ''

  return <T = Query>(query: T) => {
    const expand: Path = (query as any)['expand'] || []

    const _extraExpands = extraExpands.map(exp => `${_prefix}${exp}`)

    const processedExpandStr = isString(expand)
      ? `${expand},${_extraExpands.join(',')}`
      : [...expand, ...extraExpands].map(parseExpand).join(',')

    return {
      ...query,
      expand: processedExpandStr,
    } as T
  }
}
