export enum PermissionNameEnum {
  VisitorAdminVisitorWrite = 'seiue.visitor.admin.visitor.write',
  VisitorAdminVisitorRead = 'seiue.visitor.admin.visitor.read',
  VisitorAdminVisitorExport = 'seiue.visitor.admin.visitor.export',
  VisitorAdminAuthWrite = 'seiue.visitor.admin.auth.write',
  VisitorAdminAuthRead = 'seiue.visitor.admin.auth.read',
  VisitorStaffSingleEnabled = 'seiue.visitor.staff.single.enabled',
  VisitorStaffMultipleEnabled = 'seiue.visitor.staff.multiple.enabled',
  VisitorStaffEventEnabled = 'seiue.visitor.staff.event.enabled',
  CoreAdminAuthRead = 'seiue.core.admin.auth.read',
  CoreAdminAuthWrite = 'seiue.core.admin.auth.write',
  CoreAdminSettingWrite = 'seiue.core.admin.setting.write',
  CoreModuleAuthRead = 'seiue.core.module.auth.read',
  CoreModuleAuthWrite = 'seiue.core.module.auth.write',
  CorePluginRead = 'seiue.core.plugin.read',
  CorePluginWrite = 'seiue.core.plugin.write',
  CoreUserRead = 'seiue.core.user.read',
  CoreUserWrite = 'seiue.core.user.write',
  CoreSemesterWrite = 'seiue.core.semester.write',
  CorePlaceRead = 'seiue.core.place.read',
  CorePlaceWrite = 'seiue.core.place.write',
  CoreClassRead = 'seiue.core.class.read',
  CoreClassWrite = 'seiue.core.class.write',
  CoreGradeRead = 'seiue.core.grade.read',
  CoreGradeWrite = 'seiue.core.grade.write',
  CoreCourseRead = 'seiue.core.course.read',
  CoreCourseWrite = 'seiue.core.course.write',
  CoreDormRead = 'seiue.core.dorm.read',
  CoreDormWrite = 'seiue.core.dorm.write',
  CoreAdminClassRead = 'seiue.core.admin_class.read',
  CoreAdminClassWrite = 'seiue.core.admin_class.write',
  CoreNotificationRead = 'seiue.core.notification.read',
  CoreNotificationWrite = 'seiue.core.notification.write',
  CoreNotificationSend = 'seiue.core.notification.send',
  CoreGrowthSettingWrite = 'seiue.core.growth.setting.write',
  CoreExamRead = 'seiue.core.exam.read',
  CoreExamWrite = 'seiue.core.exam.write',
  CoreEvaluationRead = 'seiue.core.evaluation.read',
  CoreEvaluationWrite = 'seiue.core.evaluation.write',
  CoreGradeReportWrite = 'seiue.core.grade_report.write',
  CoreDirectionRead = 'seiue.core.direction.read',
  CoreDirectionWrite = 'seiue.core.direction.write',
  CoreScheduleSend = 'seiue.core.schedule.send',
  CoreGzArchiveRead = 'seiue.core.gz_archive.read',
  CoreTeacherArchiveRead = 'seiue.core.teacher_archive.read',
  CoreSemesterReviewRead = 'seiue.core.semester_review.read',
  CoreSemesterReviewWrite = 'seiue.core.semester_review.write',
  SzExamReportRead = 'seiue.sz_exam_report.read',
  SzExamReportDownload = 'seiue.sz_exam_report.download',
  SzGradeRankRead = 'seiue.sz_grade_rank.read',
  SzSemesterReportRead = 'seiue.sz_semester_report.read',
  SzSemesterReportDownload = 'seiue.sz_semester_report.download',
  SzGlobalAcademicReportRead = 'seiue.sz_global_academic_report.read',
  SzGlobalAcademicReportDownload = 'seiue.sz_global_academic_report.download',
  SzChuguoReportRead = 'seiue.sz_chuguo_report.read',
  SzChuguoReportDownload = 'seiue.sz_chuguo_report.download',
  SzResitReportRead = 'seiue.sz_resit_report.read',
  SzResitReportDownload = 'seiue.sz_resit_report.download',
  SzNjcjhzReportDownload = 'seiue.sz_njcjhz_report.download',
  SzJbcjhzReportDownload = 'seiue.sz_jbcjhz_report.download',
  SzBjcjhzReportDownload = 'seiue.sz_bjcjhz_report.download',
  SzKmcjhzReportDownload = 'seiue.sz_kmcjhz_report.download',
  SzDkjfdbReportDownload = 'seiue.sz_dkjfdb_report.download',
  SzDuokjfdbReportDownload = 'seiue.sz_duokjfdb_report.download',
  SzDuokjfdb2ReportDownload = 'seiue.sz_duokjfdb2_report.download',
  SzDankgffxReportDownload = 'seiue.sz_dankgffx_report.download',
  SzDuokgffxReportDownload = 'seiue.sz_duokgffx_report.download',
  SzDankecjfdfxReportDownload = 'seiue.sz_dankecjfdfx_report.download',
  SzDuokecjfdfxReportDownload = 'seiue.sz_duokecjfdfx_report.download',
  SzJffxReportDownload = 'seiue.sz_jffx_report.download',
  ClassScheduleTeacherScheduleRead = 'seiue.class-schedule.teacher_schedule.read',
  ClassScheduleTeacherScheduleDownload = 'seiue.class-schedule.teacher_schedule.download',
  ClassScheduleStudentScheduleRead = 'seiue.class-schedule.student_schedule.read',
  ClassScheduleStudentScheduleDownload = 'seiue.class-schedule.student_schedule.download',
  ClassScheduleClassroomScheduleRead = 'seiue.class-schedule.classroom_schedule.read',
  ClassScheduleClassroomScheduleDownload = 'seiue.class-schedule.classroom_schedule.download',
  ClassScheduleGroupScheduleRead = 'seiue.class-schedule.group_schedule.read',
  ClassScheduleGroupScheduleDownload = 'seiue.class-schedule.group_schedule.download',
  ClassScheduleAdminClassScheduleRead = 'seiue.class-schedule.admin_class_schedule.read',
  ClassScheduleAdminClassScheduleDownload = 'seiue.class-schedule.admin_class_schedule.download',
  CgrExamReportRead = 'seiue.cgr_exam_report.read',
  CgrExamReportDownload = 'seiue.cgr_exam_report.download',
  GzzxHistoryCgrExamReportRead = 'seiue.gzzx_history_cgr_exam_report.read',
  GzzxHistoryCgrExamReportDownload = 'seiue.gzzx_history_cgr_exam_report.download',
  GzzxAssessmentCgrExamReportRead = 'seiue.gzzx_assessment_cgr_exam_report.read',
  GzzxAssessmentCgrExamReportDownload = 'seiue.gzzx_assessment_cgr_exam_report.download',
  GzzxCgrExamReportRead = 'seiue.gzzx_cgr_exam_report.read',
  GzzxCgrExamReportDownload = 'seiue.gzzx_cgr_exam_report.download',
  CgrSzwSemesterReportRead = 'seiue.cgr_szw_semester_report.read',
  CgrSzwSemesterReportDownload = 'seiue.cgr_szw_semester_report.download',
  PsychologicalAdminAuthRead = 'seiue.psychological.admin.auth.read',
  PsychologicalAdminAuthWrite = 'seiue.psychological.admin.auth.write',
  PsychologicalResponsibilityRead = 'seiue.psychological.responsibility.read',
  PsychologicalResponsibilityCreate = 'seiue.psychological.responsibility.create',
  PsychologicalResponsibilityUpdate = 'seiue.psychological.responsibility.update',
  PsychologicalResponsibilityDelete = 'seiue.psychological.responsibility.delete',
  PsychologicalPositionCreate = 'seiue.psychological.position.create',
  PsychologicalPositionUpdate = 'seiue.psychological.position.update',
  PsychologicalPositionDelete = 'seiue.psychological.position.delete',
  PsychologicalRecordReport = 'seiue.psychological.record.report',
  PsychologicalRecordRead = 'seiue.psychological.record.read',
  PsychologicalRecordExport = 'seiue.psychological.record.export',
  PsychologicalAssessmentReportCreateByReport = 'seiue.psychological.assessment.report.create_by_report',
  PsychologicalAssessmentManage = 'seiue.psychological.assessment.manage',
  PsychologicalAssessmentResultListRead = 'seiue.psychological.assessment_result_list.read',
  PsychologicalAssessmentRead = 'seiue.psychological.assessment.read',
  PsychologicalAssessmentResultStatsRead = 'seiue.psychological.assessment_result_stats.read',
  PsychologicalAssessmentResultDetailRead = 'seiue.psychological.assessment_result_detail.read',
  PsychologicalPersonalAssessmentResultListRead = 'seiue.psychological.personal_assessment_result_list.read',
  PsychologicalPersonalAssessmentResultDetailRead = 'seiue.psychological.personal_assessment_result_detail.read',
  PsychologicalAssessmentCreate = 'seiue.psychological.assessment.create',
  PsychologicalAssessmentStart = 'seiue.psychological.assessment.start',
  PsychologicalAssessmentEnd = 'seiue.psychological.assessment.end',
  PsychologicalAssessmentDelay = 'seiue.psychological.assessment.delay',
  PsychologicalAssessmentEdit = 'seiue.psychological.assessment.edit',
  PsychologicalAssessmentDelete = 'seiue.psychological.assessment.delete',
  PsychologicalAssessmentUnsubmittedStudentNotice = 'seiue.psychological.assessment_unsubmitted_student.notice',
  PsychologicalAssessmentResultExport = 'seiue.psychological.assessment_result.export',
  PsychologicalAssessmentResultStatsExport = 'seiue.psychological.assessment_result_stats.export',
  PsychologicalProfileRead = 'seiue.psychological.profile.read',
  PsychologicalProfileImport = 'seiue.psychological.profile.import',
  PsychologicalProfileBgInfoButtonUpdate = 'seiue.psychological.profile.bg_info_button.update',
  PsychologicalProfileCollaboratorUpdate = 'seiue.psychological.profile.collaborator.update',
  PsychologicalProfileHomeBgInfoUpdate = 'seiue.psychological.profile.home_bg_info.update',
  PsychologicalProfileHomeBgInfoCreate = 'seiue.psychological.profile.home_bg_info.create',
  PsychologicalProfileBgInfoSetUpdate = 'seiue.psychological.profile.bg_info_set.update',
  PsychologicalProfileProblemSetUpdate = 'seiue.psychological.profile.problem_set.update',
  PsychologicalProfileInterventionSetUpdate = 'seiue.psychological.profile.intervention_set.update',
  PsychologicalProfileExport = 'seiue.psychological.profile.export',
  PsychologicalProfileLogExport = 'seiue.psychological.profile_log.export',
  PsychologicalEiManageRead = 'seiue.psychological.ei.manage.read',
  PsychologicalEiTabRead = 'seiue.psychological.ei.tab.read',
  PsychologicalEiHomeDetailRead = 'seiue.psychological.ei.home_detail.read',
  PsychologicalEiHomeEvaluationCreate = 'seiue.psychological.ei.home_evaluation.create',
  PsychologicalEiHomeInterventionCreate = 'seiue.psychological.ei.home_intervention.create',
  PsychologicalEiManageEvaluationUpdate = 'seiue.psychological.ei.manage_evaluation.update',
  PsychologicalEiManageInterventionCreate = 'seiue.psychological.ei.manage_intervention.create',
  PsychologicalEiManageInterventionEndUpdate = 'seiue.psychological.ei.manage_intervention_end.update',
  PsychologicalEiManageInterventionUpdate = 'seiue.psychological.ei.manage_intervention.update',
  PsychologicalEiEvaluationCreate = 'seiue.psychological.ei.evaluation.create',
  PsychologicalEiInterventionCreate = 'seiue.psychological.ei.intervention.create',
  PsychologicalEiExport = 'seiue.psychological.ei.export',
  PsychologicalChatRead = 'seiue.psychological.chat.read',
  PsychologicalChatWrite = 'seiue.psychological.chat.write',
  PsychologicalChatExport = 'seiue.psychological.chat.export',
  PsychologicalForewarningRead = 'seiue.psychological.forewarning.read',
  PsychologicalForewarningWrite = 'seiue.psychological.forewarning.write',
  PsychologicalForewarningExport = 'seiue.psychological.forewarning.export',
  CreditRead = 'seiue.credit.read',
  CreditWrite = 'seiue.credit.write',
  CreditDownload = 'seiue.credit.download',
  HandoutRead = 'seiue.handout.read',
  HandoutWrite = 'seiue.handout.write',
  BankRead = 'seiue.bank.read',
  BankWrite = 'seiue.bank.write',
  CoreDeclarationWrite = 'seiue.core.declaration.write',
  QuestionnaireQuestionnaireCreate = 'seiue.questionnaire.questionnaire.create',
  CallerInviterEnabled = 'seiue.caller.inviter.enabled',
  CallerWatchEnabled = 'seiue.caller.watch.enabled',
  ExamReportRead = 'seiue.exam_report.read',
  StudentStatusConfigAdmin = 'seiue.student_status.config.admin',
  SchoolSuperAdmin = 'seiue.school.super_admin',
  MentorAdminMemberWrite = 'seiue.mentor.admin.member.write',
  MentorAdminMemberRead = 'seiue.mentor.admin.member.read',
  MentorAdminTalkRead = 'seiue.mentor.admin.talk.read',
  MentorAdminTaskRead = 'seiue.mentor.admin.task.read',
  MentorAdminTaskWrite = 'seiue.mentor.admin.task.write',
  MentorAdminAuthWrite = 'seiue.mentor.admin.auth.write',
  MentorAdminAuthRead = 'seiue.mentor.admin.auth.read',
  MentorMentorMenteeTask = 'seiue.mentor.mentor.mentee.task',
  MentorMentorMenteeTalk = 'seiue.mentor.mentor.mentee.talk',
}
