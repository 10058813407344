/**
 * @file 单点登录
 */

import { lazy } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

export const initSSO: InitPluginFeature = {
  name: PluginNameEnum.SSO,
  init: () => ({
    setting: {
      type: 'module' as const,
      component: lazy(() =>
        import('./pages/admin/PluginSetting').then(m => ({
          default: m.Setting,
        })),
      ),
    },
    slots: {
      adminUserManagementActions: () =>
        import('./slots/adminUserManagementActions').then(
          m => m.adminUserManagementActions,
        ),
      adminUserFieldManagementCustomGroups: () =>
        import('./slots/adminUserFieldManagementCustomGroups').then(
          m => m.adminUserFieldManagementCustomGroups,
        ),
      userTableHelper: () =>
        import('./slots/userTableHelper').then(m => m.userTableHelper),
      adminUserFormHelper: () =>
        import('./slots/adminUserFormHelper').then(m => m.adminUserFormHelper),
    },
  }),
}
