import { lazy } from 'react'

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminCopilotMenu } from './menus'
import { modalRoutes, routes } from './routes'
import { widgets } from './widgets'

/**
 * Copilot Demo，销售演示用
 */
export const initCopilotDemo: InitPluginFeature = {
  name: PluginNameEnum.CopilotDemo,
  init: () => {
    return {
      slots: {
        layoutMain: lazy(() =>
          import('./slots/layoutMain').then(m => ({
            default: m.AIEntry,
          })),
        ),
        homeLayoutHeaderRight: lazy(() =>
          import('./slots/homeLayoutHeaderRight').then(m => ({
            default: m.CopilotHeaderEntry,
          })),
        ),
        homeReplaceContent: lazy(() =>
          import('./slots/AIHome').then(m => ({
            default: m.AIHomeSlot,
          })),
        ),
        StudentProfileHomeLeftCol: lazy(() =>
          import('./slots/ProfileCard').then(m => ({
            default: m.CopilotProfileCard,
          })),
        ),
      },
      widgets,
      routes,
      modalRoutes,
      menus: [adminCopilotMenu],
    }
  },
}
