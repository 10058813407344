/**
 * @file 动态菜单
 */

import { atom } from 'jotai'

import { hasUsablePluginAtom } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { MenuType } from 'packages/web-layout/types'

export const userGzFreshmanMenu = atom(get => {
  if (!get(hasUsablePluginAtom(PluginNameEnum.GzFreshmanQuestionnaire))) {
    return []
  }

  return [
    {
      name: PluginNameEnum.GzFreshmanQuestionnaire,
      label: $t('新生入学问卷'),
      path: '/plugin/gz-freshman-questionnaire/list',
      type: MenuType.Apps,
      subMenus: [
        {
          label: $t('问卷列表'),
          path: '/plugin/gz-freshman-questionnaire/list',
          icon: 'Questionnaire',
        },
      ],
    },
  ]
})
