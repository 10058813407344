/**
 * 因为无代码中，存在 app domain 的概念，所以需要在前端维护 domain 的枚举
 */
export enum ScopeDomain {
  Role = 'role',
  Reflection = 'reflection',
  Class = 'class',
  Dorm = 'dorm',
  Course = 'course',
  Grade = 'grade',
  Place = 'place',
  PlaceIotDevice = 'place_iot_device',
  AdminClass = 'admin_class',
  Notification = 'notification',
  NotificationSender = 'notification_sender',
  Assessment = 'assessment',
  Workflow = 'workflow',
  Schcal = 'schcal',
  GradeReport = 'grade_report',
  SzzxClassSchedule = 'szzx_class_schedule',
  Transcript = 'transcript',
  Cgr = 'cgr',
  Venue = 'venue',
  StudentStatus = 'student_status',
  Credit = 'credit',
  SchoolPlugin = 'school_plugin',
  SchoolPluginDisplayScopes = 'school_plugin_display_scopes',
  EventInstance = 'event_instance',
  Psychological = 'psychological',
  ScheduleSender = 'schedule_sender',
  PsychologicalReport = 'psychological_report',
  PsychologicalRecord = 'psychological_record',
  PsychologicalIntervention = 'psychological_intervention',
  Absence = 'absence',
  Handout = 'handout',
  Declaration = 'declaration',
  Accomplishment = 'accomplishment',
  StudentStatusConfig = 'student_status_config',
  GzArchive = 'gz_archive',
  AdjustmentAuditGroup = 'adjustment_audit_group',
  Questionnaire = 'questionnaire',
  Caller = 'caller', // 访客系统
  Exam = 'exam',
  Copilot = 'copilot',
}
