/**
 * @file 用户选择器语法糖
 *
 * 语法糖提供两个方法
 * pickReflection - 选择 reflections，接收 ReflectionPicker 的大部分参数，返回选中的 reflectionIds 与 selectedRows，如果你想要重置 Picker，pickReflection 接收一个 reset 参数。
 * destroyRootReflection - 主动释放 RootReflectionPicker。
 */
import { Toast } from '@seiue/ui'
import { noop, flatten, compact } from '@seiue/util'
import React, { lazy, Suspense } from 'react'

import { registerGlobalPicker } from 'packages/components/Modal'
import { $t } from 'packages/locale'

import type { ReflectionPickerProps } from './ReflectionPicker'

const ReflectionPicker = lazy(() =>
  import('./ReflectionPicker').then(m => ({ default: m.ReflectionPicker })),
)

type Options = Omit<
  ReflectionPickerProps,
  'visible' | 'onCancel' | 'onSubmit'
> & {
  maxSelectedRows?: number // 最大支持选择的行数
}

type OnSelectParameters = Parameters<ReflectionPickerProps['onSubmit']>
export type PickReturnType = {
  ids: OnSelectParameters[0]
  rows: OnSelectParameters[1]
  flatIds: number[]
}

type PickOptions = Options & {
  reset?: boolean
}

const { getPicker, getDestory, setPicker, setDestory } = registerGlobalPicker<
  (options: PickOptions) => Promise<PickReturnType | false>
>('rootReflectionPicker')

/**
 * 人员选择器
 *
 * @param options - 选项
 * @returns 选择结果
 */
export const pickReflection: (
  options: PickOptions,
) => Promise<PickReturnType | false> | undefined = options =>
  getPicker()?.(options)

/**
 * 销毁人员选择器
 */
export const destroyRootReflection = () => {
  getDestory()?.()
}

type PickerWrapperProps = Omit<ReflectionPickerProps, 'visible'> & {
  maxSelectedRows?: number // 最大支持选择的行数
}

type PickerWrapperRef = {
  hide: () => void
}
const PickerWrapper = React.forwardRef<PickerWrapperRef, PickerWrapperProps>(
  ({ onSubmit, onCancel, maxSelectedRows, ...props }, ref) => {
    const [visible, setVisible] = React.useState(true)

    const innerSubmit = (
      ids: OnSelectParameters[0],
      rows: OnSelectParameters[1],
    ) => {
      const idsLength = Object.values(ids).reduce(
        (total, _ids) => total + (_ids?.length || 0),
        0,
      )

      if (maxSelectedRows && idsLength > maxSelectedRows) {
        Toast.error($t('最大支持选择{num}个', { num: maxSelectedRows }))
      } else {
        onSubmit(ids, rows)
        setVisible(false)
      }
    }

    const innerCancel = () => {
      onCancel()
      setVisible(false)
    }

    React.useImperativeHandle(ref, () => ({
      show: () => {
        setVisible(true)
      },
      hide: () => {
        setVisible(false)
      },
    }))

    return (
      <Suspense fallback={null}>
        <ReflectionPicker
          {...props}
          visible={visible}
          onSubmit={innerSubmit}
          onCancel={innerCancel}
        />
      </Suspense>
    )
  },
)

/**
 * 人员选择器
 *
 * 放在 App 组件根部的组件选择器，以供方法调用。
 *
 * @returns 组件
 */
export const RootReflectionPicker: React.FC = () => {
  const [inited, setInited] = React.useState(false)
  const [options, setOptions] = React.useState<Options>({
    title: '',
  })

  const [handlers, setHandlers] = React.useState({
    submit: noop as (
      ids: OnSelectParameters[0],
      rows: OnSelectParameters[1],
    ) => void,
    cancel: noop,
  })

  const pickerRef = React.useRef<any>() // 这个 ref 还是需要保留，因为它是用于控制 PickerWrapper 显示/隐藏的

  React.useEffect(() => {
    setPicker(async ({ reset, ...newOptions }) => {
      if (!inited) setInited(true)
      else if (reset) {
        setInited(false)
        // 将 setInited 放在下一帧执行，保证 inited 先被设置为 false
        requestAnimationFrame(() => {
          setInited(true)
        })
      }

      setOptions({
        ...newOptions,
      })

      return new Promise(resolve => {
        setHandlers({
          submit: (ids, rows) => {
            resolve({
              ids,
              rows,
              flatIds: compact(flatten(Object.values(ids))),
            })
          },
          cancel: () => {
            resolve(false)
          },
        })

        /*
         * 刚初始化时，PickerWrapper 还没渲染完毕，pickerRef 会处于无值状态
         * 别担心，PickerWrapper 第一次初始化默认为显示状态
         */
        pickerRef.current?.show()
      })
    })

    setDestory(async () => {
      pickerRef.current?.hide()

      // remove Modal dom after transition finished
      setTimeout(() => {
        setInited(false)
      }, 320)
    })
  }, [inited])

  if (!inited) return null

  return (
    <PickerWrapper
      ref={pickerRef}
      {...options}
      onSubmit={handlers.submit}
      onCancel={handlers.cancel}
    />
  )
}
