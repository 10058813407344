/**
 * @file 注册 chalk 的通知详情导航
 */

import { Navigator } from 'packages/features/messages/register/types'
import { registerMessageNavigator } from 'packages/features/messages/utils'
import { PublishReceiverTypeEnum } from 'packages/sdks-next/nuwa'

type Result = {
  id: number
  receiverIds: number[]
  ccIds: number[]
  managerIds: number[]
}

registerMessageNavigator(['reporting.warning_received'], msg => {
  const { link } =
    (msg.attributes as {
      link?: string
    }) || {}

  return link
    ? {
        path: link,
        pathInNewWindow: true,
      }
    : null
})

/**
 * 报表发布消息的跳转处理
 *
 * @param msg - 消息
 * @param currentReflection - 当前用户
 * @returns 跳转处理
 */
export const reportPublishPublishedNavigator: Navigator = (
  msg,
  currentReflection,
) => {
  const { reportPublishId, results } =
    (msg.attributes as {
      reportPublishId?: number
      results: Result[]
    }) || {}

  const { managerResults, ccResults, ownerResults } = results.reduce(
    (acc, result) => {
      if (result.managerIds?.includes(currentReflection.id)) {
        acc.managerResults.push(result)
      }

      if (result.ccIds?.includes(currentReflection.id)) {
        acc.ccResults.push(result)
      }

      if (result.receiverIds?.includes(currentReflection.id)) {
        acc.ownerResults.push(result)
      }

      return acc
    },
    {
      managerResults: [] as Result[],
      ccResults: [] as Result[],
      ownerResults: [] as Result[],
    },
  )

  // 如果是管理员身份
  if (managerResults.length) {
    return {
      path:
        managerResults.length > 1
          ? `/user/report-center/report-published/publishes/${reportPublishId}/results?receiverType=${PublishReceiverTypeEnum.Manager}`
          : `/user/report-center/report-published/publishes/${reportPublishId}/detail?receiverType=${PublishReceiverTypeEnum.Manager}&resultId=${managerResults[0].id}`,
    }
  }

  if (ccResults.length || ownerResults.length) {
    const firstResult = ccResults.length ? ccResults[0] : ownerResults[0]
    const receiverType = ccResults.length
      ? PublishReceiverTypeEnum.Cc
      : PublishReceiverTypeEnum.Owner

    return {
      path:
        ownerResults.length > 1 || ccResults.length > 1
          ? `/user/report-center/report-published/received/${reportPublishId}/results?receiverType=${receiverType}`
          : `/user/report-center/report-published/received/${reportPublishId}/detail?receiverType=${receiverType}&resultId=${firstResult.id}`,
    }
  }

  return null
}

registerMessageNavigator(
  [
    'report_dashboard.report_publish_published',
    'report_report.report_publish_published',
  ],
  reportPublishPublishedNavigator,
)

registerMessageNavigator(
  [
    'report_dashboard.report_publish_revoked',
    'report_report.report_publish_revoked',
  ],
  () => {
    return null
  },
)
