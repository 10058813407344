/**
 * @file reflection view helpers
 */

import { createBoolEnumHelper, createEnumHelper } from '@seiue/util'

import { i18nConnect } from 'packages/feature-utils/i18n/utils'
import { $ct, $t, createLocaleMessageHelper } from 'packages/locale'
import {
  RoleEnum,
  GenderEnum,
  SecuredReflection,
} from 'packages/sdks-next/chalk'

export const roleToLabel = createEnumHelper(() => ({
  [RoleEnum.Student]: $t('学生'),
  [RoleEnum.Teacher]: $t('教师'),
  [RoleEnum.Guardian]: $t('家长'),
  [RoleEnum.Staff]: $t('校外人员'),
  [RoleEnum.Shadow]: $t('用户'),
}))

/**
 * 获取家长字段 label
 *
 * @returns label
 */
export const getGuardianFieldLabels = () => ({
  name: $t('姓名'),
  account: $t('账号'),
  phone: $t('手机'),
  guardianRoleId: $t('身份'),
})

export const activeStatusToLabel = createBoolEnumHelper(() => [
  $t('已激活'),
  $t('未激活'),
])

export const genderToLabel = createEnumHelper(() => ({
  [GenderEnum.F]: $t('女'),
  [GenderEnum.M]: $t('男'),
}))

export const getArchivedTag = createLocaleMessageHelper(() => ({
  禁用: $t('禁用'),
  毕业: $t('毕业'),
  休学: $t('休学'),
  退学: $t('退学'),
  离职: $t('离职'),
  停职: $t('停职'),
})).get

/**
 * 获取用户带年级的行政班列表
 *
 * @param reflection - 用户信息
 * @returns 行政班列表
 */
export const getReflectionAdminClassesLabels = (
  reflection: Pick<SecuredReflection, 'adminClasses' | 'grade'>,
) => {
  return (
    reflection.adminClasses?.map(adminClass => {
      const gradeName = reflection.grade?.name
        ? $ct(reflection.grade?.name, 'grade')
        : ''

      return i18nConnect(gradeName, adminClass)
    }) ?? []
  )
}

/**
 * 获取用户带年级的届别
 *
 * @param reflection - 用户信息
 * @returns 用户带年级的届别
 */
export const getReflectionGraduatesInAndGradeLabel = (
  reflection: Pick<SecuredReflection, 'graduatesIn' | 'grade'>,
) => {
  return `${reflection.graduatesIn?.name ?? ''}${
    reflection.grade ? `(${reflection.grade.name})` : ''
  }`
}

/**
 * 获取用户性别 label
 *
 * @param reflection - 用户信息
 * @returns 性别 label
 */
export const getReflectionGenderLabel = (
  reflection: Pick<SecuredReflection, 'gender'>,
) => {
  return reflection.gender ? genderToLabel.get(reflection.gender) : undefined
}
