/**
 * @file 用户头像
 */

import { env } from '@seiue/env'
import { Popover, Divider, Toast } from '@seiue/ui'
import React from 'react'
import { useDispatch } from 'react-redux'
import styled, { css } from 'styled-components'

import { Avatar } from 'packages/components/Avatar'
import { useSchoolConfigs } from 'packages/feature-utils/school-configs'
import { useReflectionName } from 'packages/features/reflections/utils'
import {
  useCurrentReflection,
  useCurrentUserReflectionsWithFullData,
  useReflectionsUnreadStatus,
} from 'packages/features/sessions/utils'
import { $t } from 'packages/locale'
import {
  SchoolConfigGroupNameEnum,
  ServiceEnum,
} from 'packages/shared-stores/configs'
import { TextColor } from 'packages/theme'

import { useHistory } from '@/router'

import { ReflectionItem } from './ReflectionItem'

export type ReflectionAvatarItem = {
  label?: string
  onClick?: () => void
  active?: boolean
  testId?: string
  /**
   * 使用 Item 接管单元格渲染
   */
  item?: React.ReactNode
}

export type ReflectionAvatarItemGroups = {
  style?: React.CSSProperties
  items: ReflectionAvatarItem[]
}[]

const MenuItem: React.FC<{
  item: ReflectionAvatarItem
  active?: boolean
}> = ({ item, active }) => (
  <Item active={active}>
    {item.item ? (
      item.item
    ) : (
      <SingleItem data-test-id={item.testId || ''} onClick={item.onClick}>
        {item.label}
      </SingleItem>
    )}
  </Item>
)

/**
 * 右上角用户头像菜单内容区组件
 *
 * @returns react element
 */
export const ReflectionAvatarPopoverContent: React.FC = () => {
  const history = useHistory()

  const { configs } = useSchoolConfigs({
    service: ServiceEnum.Chalk,
    groupIn: [SchoolConfigGroupNameEnum.UserMenu],
  })

  const showAppDownload = !!configs?.show_app_download

  const reflection = useCurrentReflection()
  const reflections = useCurrentUserReflectionsWithFullData()

  // 获取对应账号的未读消息
  const reflectionsUnreadMap = useReflectionsUnreadStatus()

  const isSingleReflection = reflections.length === 1

  const reflectionAvatarHeader = React.useMemo(() => {
    if (isSingleReflection) {
      const theR = reflections[0]
      return (
        <ReflectionItem
          isSingleReflection={true}
          reflection={theR}
          active={false}
          unread={reflectionsUnreadMap[theR.id]}
        />
      )
    }

    return undefined
  }, [isSingleReflection, reflections, reflectionsUnreadMap])

  const groups: ReflectionAvatarItemGroups = React.useMemo(() => {
    const secondGroup = {
      style: {
        marginTop: '8px',
      },
      items: [
        {
          label: $t('个人资料'),
          onClick: () => {
            history.push('Me.UserInfo')
          },
        },
        {
          label: $t('账号设置'),
          onClick: () => {
            history.push('Me.UserSettings')
          },
        },
      ],
    }

    if (showAppDownload) {
      secondGroup.items.push({
        label: $t('下载希悦校园 App'),
        onClick: () => {
          import('@/components/DownloadAppModal').then(
            ({ openDownloadAppModal }) => {
              openDownloadAppModal()
            },
          )
        },
      })
    }

    if (isSingleReflection) return [secondGroup]

    return [
      {
        items: reflections.map(refl => {
          const active = refl.id === reflection.id
          return {
            active,
            item: (
              <ReflectionItem
                reflection={refl}
                active={active}
                unread={reflectionsUnreadMap[refl.id]}
                onClick={() => {
                  if (refl.disabled) {
                    Toast.warning($t('该身份已被禁用'))
                    return
                  }

                  // RN Webview 中切换用户身份向上 postMessage
                  if ((window as any).ReactNativeWebView) {
                    ;(window as any).ReactNativeWebView.postMessage(
                      JSON.stringify({
                        type: 'switchReflection',
                        data: JSON.stringify(refl),
                      }),
                    )
                  }

                  if (!active) {
                    window.location.href = `${env('CLIENT_CHALK_3')}/?rid=${
                      refl.id
                    }`
                  }
                }}
              />
            ),
          }
        }),
      },
      secondGroup,
    ]
  }, [
    showAppDownload,
    isSingleReflection,
    reflections,
    history,
    reflection.id,
    reflectionsUnreadMap,
  ])

  const dispatch: any = useDispatch()
  const name = useReflectionName(reflection)

  const usedGroups = React.useMemo(() => {
    const _groups = groups
      ? [...groups]
      : ([{ items: [] }] as ReflectionAvatarItemGroups)

    _groups[_groups.length - 1].items.push({
      label: $t('退出'),
      onClick: () => dispatch.session.destroy(),
    })

    return _groups
  }, [groups, dispatch])

  return (
    <List>
      {reflectionAvatarHeader || <Name>{name}</Name>}
      <Divider />
      {usedGroups.map((group, idx) => {
        const isLast = idx === usedGroups.length - 1

        return (
          <Group key={idx} style={group.style}>
            {group.items.map((itm, index) => (
              <MenuItem key={index} item={itm} active={itm.active} />
            ))}
            {!isLast && <UserItemDivider />}
          </Group>
        )
      })}
    </List>
  )
}

/**
 * 右上角用户头像
 *
 * @returns react element
 */
export const ReflectionAvatar: React.FC = () => {
  const reflection = useCurrentReflection()

  return (
    <Popover
      placement="bottomRight"
      content={<ReflectionAvatarPopoverContent />}
      contentStyle={{ minWidth: 240, maxHeight: 720 }}
    >
      <Content data-test-id="导航栏_用户头像">
        <Avatar reflection={reflection} size={32} />
      </Content>
    </Popover>
  )
}

const Content = styled.div`
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const List = styled.div`
  padding-bottom: 8px;
`

const Name = styled.p`
  margin: 0;
  padding: 16px 20px;
  color: ${TextColor._1};
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
`

const Group = styled.div``

const Item = styled.div<{ active?: boolean }>`
  cursor: pointer;
  font-size: 13px;
  transition: background-color 0.3s;
  color: ${TextColor._2};

  &:hover {
    background-color: ${({ active, theme }) =>
      active ? theme.brand._1 : '#f6f8fa'};
  }

  ${({ active }) =>
    active &&
    css`
      color: #fff;
      background-color: ${p => p.theme.brand._1};

      > * {
        color: #fff !important;
      }
    `}
`

const UserItemDivider = styled(Divider)``

const SingleItem = styled.div`
  padding: 11px 20px;
  color: #323842;
  font-weight: 400;
  line-height: 18px;
`
