import { useGetGroupByBiz } from 'packages/feature-utils/groups/apis'
import {
  PluginEnum,
  ScopeDomain,
  useHasEnabledParentPlugin,
} from 'packages/feature-utils/plugins'
import {
  GroupPermissionTypeEnum,
  GroupRoleEnum,
} from 'packages/features/groups/types'
import { useGroupPermissions } from 'packages/features/groups/utils/apis'
import { useCurrentReflection } from 'packages/features/sessions/utils/data'
import {
  GroupTypeEnum,
  EnhancerEnum,
  PermissionNameEnum,
  MemberTypeEnum,
  GetRolesByReflectionQuery,
  roleApi$queryManagedScopes,
  groupApi$getPermissionsByGroupType,
  groupApi$getRolesByReflection,
} from 'packages/sdks-next/chalk'

/**
 * 获取登录用户在学生档案里所具有的权限
 * 比如导生详情页面，老师有这个学生的的权限才能跳转到此学生的档案页面
 *
 * @deprecated 请使用 useHasStudentProfilePermissionFn
 *
 * @param profileRid - 要查看的学生 id
 * @returns [是否有权限, 权限列表, 加载状态]
 */
export const useHasProfilePermissionOfStudent = (
  profileRid?: number | string | null,
) => {
  const currentReflection = useCurrentReflection()

  const pluginEnabled = useHasEnabledParentPlugin(PluginEnum.GzArchive)

  const queryScopes = pluginEnabled
    ? {
        domain: ScopeDomain.GzArchive,
        query: {
          type: EnhancerEnum.StudentScope,
          permission: PermissionNameEnum.CoreGzArchiveRead,
        },
      }
    : {
        domain: ScopeDomain.Reflection,
        query: {
          type: EnhancerEnum.StudentScope,
          permission: PermissionNameEnum.CoreUserRead,
        },
      }

  // 管理员，只需请求 queryManagedScopes 即可
  const { data: scopes, loading: scopeLoading } =
    roleApi$queryManagedScopes.useApi(
      {
        id: currentReflection.id,
        ...queryScopes,
      },
      {
        disable: !profileRid,
      },
    )

  const rid = typeof profileRid === 'symbol' ? 0 : Number(profileRid)

  const hasScope = scopes?.some(v => v.resourceIds?.includes(rid)) || false
  const hasNoScopeForSure = !scopeLoading && !hasScope

  // 非管理员，需要请求 getPermissionsByReflection
  const [permissions, tempHasPermission, permissionLoading] =
    useGroupPermissions({
      type: GroupTypeEnum.RelationGroup,
      bizId: rid,
      waitFor: !!(rid && hasNoScopeForSure),
    })

  const hasPermission = (permName: string) =>
    hasScope || tempHasPermission(permName)

  // 先通过请求 scope 判断，确定没有 scope 的时候再通过 useGroupPermissions 判断
  const loading = hasNoScopeForSure
    ? scopeLoading || permissionLoading
    : scopeLoading

  const hasOnePermission = hasScope || Boolean(permissions?.length)
  // 判断是否有某个权限的时候，管理员 permissions 返回为 []，需要用 hasPermission 判断
  return [hasOnePermission, hasPermission, loading, hasScope] as const
}

/**
 * 判断指定角色是否有指定的档案权限
 * 比如班主任在成员页面有档案权限的话，才能通过点击学生头像进入到学生的档案页面
 *
 * @param args - 参数
 * @param args.disable - 是否禁用
 * @returns [判断档案权限的函数, 加载状态]
 */
export const useHasProfilePermissionFunction = ({
  disable = false,
}: { disable?: boolean } = {}) => {
  const { data: permissions, loading } =
    groupApi$getPermissionsByGroupType.useApi(GroupTypeEnum.RelationGroup, {
      staleTime: 5 * 60,
      disable,
    })

  const hasPermission = (
    memberType: string | MemberTypeEnum,
    permName: GroupPermissionTypeEnum | string,
  ) => {
    return permissions?.some(
      v => v.permName === permName && v.memberType === memberType,
    )
  }

  return [hasPermission, loading] as const
}

/**
 * 获取与给定学生在群组里的所有角色，通常用于别人（非超管）查看学生档案时和学生的关系，比如家长或者教师
 *
 * @param args - 参数
 * @param args.rid - 学生 id
 * @param args.getRolesQuery - 查询角色列表的参数
 * @returns 角色列表
 */
export const useGetGroupRolesByRid = ({
  rid,
  getRolesQuery,
}: {
  rid?: number
  getRolesQuery?: GetRolesByReflectionQuery
}): GroupRoleEnum[] | null => {
  const currentReflection = useCurrentReflection()
  // 获取和学生相关联的档案 group
  const { data: group } = useGetGroupByBiz(
    {
      type: GroupTypeEnum.RelationGroup,
      bizId: `${rid}`,
    },
    {
      disable: !rid,
    },
  )

  // 获取当前登录用户在 group 里面的 roles
  const { data: roles } = groupApi$getRolesByReflection.useApi(
    {
      groupId: Number(group?.id),
      rid: currentReflection.id,
      query: getRolesQuery,
    },
    {
      disable: !group,
    },
  )

  return roles as GroupRoleEnum[] | null
}
