/**
 * @file Util functions of permissions and roles
 */
import { createEnumHelper, isEqual } from '@seiue/util'

import { ScopeDomain } from 'packages/feature-utils/plugins'
import {
  EnhancerEnum,
  PermissionNameEnum,
  Role,
  RoleEnum,
  Scope,
  ScopeAssignment,
  ScopeEnhancerEnum,
} from 'packages/sdks-next/chalk'

import { RoleQuery } from './types'

/**
 * 从指定 scopeAssignments 中查找是否存在特定权限
 *
 * @param scopeAssignments - 从这些 scopeAssignments 里查找
 * @param permission - 查找此权限
 * @returns 是否存在
 */
export const hasPermissionInScopeAssignments = (
  scopeAssignments: ScopeAssignment[],
  permission: PermissionNameEnum,
) => scopeAssignments.some(({ scopeId }) => scopeId === permission)

/**
 * 筛选 roles
 *
 * @param roles - 从这些 roles 里筛选
 * @param query - 筛选条件
 * @param query.permission - 权限
 * @param query.ids - ids
 * @param query.enhancer - enhancer
 * @returns 筛选后的 roles
 */
export const filterRoles = (
  roles: Role[],
  { permission, ids, enhancer }: Omit<RoleQuery, 'isManager'>,
) =>
  roles.filter(role => {
    if (ids) {
      if (!ids.includes(role.id)) return false
    }

    if (permission) {
      if (
        !role.assignments ||
        !hasPermissionInScopeAssignments(role.assignments, permission)
      )
        return false
    }

    if (enhancer) {
      if (role.labels?.type !== EnhancerEnum.CoreRole) return false
    }

    return true
  })

/**
 * 查看 scope 列表是否对应某个资源的所有内容
 *
 * @param scopes - Scope 列表
 * @param scope - 资源所对应的 ScopeEnhancer
 * @returns 是否对应
 */
export const hasFullScope = (scopes: Scope[], scope: ScopeEnhancerEnum) =>
  scopes.some(
    ({ labels, params }) => isEqual(labels?.type, scope) && isEqual(params, {}),
  )

/**
 * 根据 manager 筛选出对应的 roles
 *
 * @param managerRoles - 管理者角色
 * @param managedRoles - 被管理者角色
 * @param isManager - 是否是管理者
 * @returns 筛选后的 roles
 */
export const getRolesByIsManager = (
  managerRoles: Role[],
  managedRoles: Role[],
  isManager?: boolean,
) => {
  if (isManager === true) {
    return managerRoles
  }

  if (isManager === false) {
    return managedRoles
  }

  return [...managerRoles, ...managedRoles]
}

/**
 * 根据 Enhancer 拿到对应的角色
 *
 * @param enhancer - Enhancer
 * @returns 角色
 */
export const getRoleByEnhancer = (enhancer?: EnhancerEnum | null) => {
  switch (enhancer) {
    case EnhancerEnum.StudentScope:
      return RoleEnum.Student
    case EnhancerEnum.TeacherScope:
      return RoleEnum.Teacher
    case EnhancerEnum.GuardianScope:
      return RoleEnum.Guardian
    case EnhancerEnum.StaffScope:
      return RoleEnum.Staff
    default:
      return null
  }
}

/**
 * 根据 role 拿到对应的 scopeEnhancer
 */
export const roleToScopeEnhancer = createEnumHelper(() => ({
  [RoleEnum.Student]: ScopeEnhancerEnum.StudentScope,
  [RoleEnum.Teacher]: ScopeEnhancerEnum.TeacherScope,
  [RoleEnum.Guardian]: ScopeEnhancerEnum.GuardianScope,
  [RoleEnum.Staff]: ScopeEnhancerEnum.StaffScope,
  [RoleEnum.Shadow]: ScopeEnhancerEnum.ReflectionScope,
}))

/**
 * 根据 permission 拿到对应的 domain
 *
 * @param permission - permission
 * @returns domain
 */
export const getDomainByCorePermission = (permission: PermissionNameEnum) => {
  switch (permission) {
    case PermissionNameEnum.CoreUserRead:
    case PermissionNameEnum.CoreUserWrite:
      return ScopeDomain.Reflection
    case PermissionNameEnum.CorePlaceRead:
    case PermissionNameEnum.CorePlaceWrite:
      return ScopeDomain.Place
    case PermissionNameEnum.CoreAdminClassRead:
    case PermissionNameEnum.CoreAdminClassWrite:
      return ScopeDomain.AdminClass
    case PermissionNameEnum.CoreCourseRead:
    case PermissionNameEnum.CoreCourseWrite:
      return ScopeDomain.Course
    case PermissionNameEnum.CoreNotificationSend:
      return ScopeDomain.NotificationSender
    case PermissionNameEnum.CoreNotificationRead:
    case PermissionNameEnum.CoreNotificationWrite:
      return ScopeDomain.Notification
    case PermissionNameEnum.CoreDormRead:
    case PermissionNameEnum.CoreDormWrite:
      return ScopeDomain.Dorm
    case PermissionNameEnum.CoreClassRead:
    case PermissionNameEnum.CoreClassWrite:
      return ScopeDomain.Class
    case PermissionNameEnum.CoreGradeRead:
    case PermissionNameEnum.CoreGradeWrite:
      return ScopeDomain.Grade
    case PermissionNameEnum.CoreGradeReportWrite:
      return ScopeDomain.GradeReport
    case PermissionNameEnum.CreditRead:
    case PermissionNameEnum.CreditWrite:
      return ScopeDomain.Credit
    case PermissionNameEnum.CoreScheduleSend:
      return ScopeDomain.ScheduleSender
    case PermissionNameEnum.ClassScheduleTeacherScheduleRead:
    case PermissionNameEnum.ClassScheduleStudentScheduleRead:
    case PermissionNameEnum.ClassScheduleClassroomScheduleRead:
    case PermissionNameEnum.ClassScheduleGroupScheduleRead:
    case PermissionNameEnum.ClassScheduleAdminClassScheduleRead:
      return ScopeDomain.SzzxClassSchedule
    case PermissionNameEnum.CoreDeclarationWrite:
      return ScopeDomain.Declaration
    case PermissionNameEnum.CoreGzArchiveRead:
      return ScopeDomain.GzArchive
    case PermissionNameEnum.CoreTeacherArchiveRead:
      return ScopeDomain.GzArchive
    case PermissionNameEnum.CoreExamWrite:
      return ScopeDomain.Exam
    default:
      return null
  }
}
