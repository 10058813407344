import { atom } from 'jotai'
import { loadable } from 'jotai/utils'

import { hasManageableParentPluginAtom } from 'packages/feature-utils/plugins'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'
import { MenuItemUnion, MenuType } from 'packages/web-layout/types'

/**
 * CopilotDemo 后台菜单
 * 从 Copilot 菜单中复制过来，后续这两个插件会合并
 */
export const adminCopilotMenu = loadable(
  atom<MenuItemUnion[]>(get => {
    if (!get(hasManageableParentPluginAtom(PluginNameEnum.CopilotDemo))) {
      return []
    }

    const subMenus: MenuItemUnion[] = []

    subMenus.push({
      label: $t('数据统计'),
      icon: 'Chart',
      path: '/admin/plugin/copilot-demo/stats',
    })

    subMenus.push({
      label: $t('知识库'),
      icon: 'BookOpen',
      path: '/admin/plugin/copilot-demo/knowledge-bases',
    })

    return [
      {
        prefix: [PluginCategoryEnum.通用工具],
        name: PluginNameEnum.CopilotDemo,
        label: $t('AI Copilot 助手管理'),
        type: MenuType.AdminApps,
        sort: 1000,
        subMenus,
      },
    ]
  }),
)
