import { FieldTypeEnum } from './field-type-enum'
import { FieldOptionSourceEnum } from './field-option-source-enum'
import { FieldValidateTypeEnum } from './field-validate-type-enum'

export interface TableFieldInterface {
  /**
   * 字段名称（同一表格内不可重复）
   */
  name: string
  /**
   * 字段标签
   */
  label: string
  /**
   * 字段类型（文本，单选，多选，数字，时间，日期）
   */
  type: FieldTypeEnum
  /**
   * 自定义字段选项来源
   */
  optionSource?: FieldOptionSourceEnum | null
  /**
   * 字段校验规则
   */
  validateType?: FieldValidateTypeEnum | null
  validateTypes?: string[] | null
  /**
   * 省市区字段 - 是否使用详细地址
   */
  enableDetailAddress?: boolean | null
  /**
   * 类型为单选或者多选时填写，为选项值
   */
  options?: string[]
  /**
   * 字段的描述
   */
  description?: string
  /**
   * 字段是否必填
   */
  required?: boolean | null
}

export class TableField implements TableFieldInterface {
  /**
   * 字段名称（同一表格内不可重复）
   */
  name: string
  /**
   * 字段标签
   */
  label: string
  /**
   * 字段类型（文本，单选，多选，数字，时间，日期）
   */
  type: FieldTypeEnum
  /**
   * 自定义字段选项来源
   */
  optionSource?: FieldOptionSourceEnum | null
  /**
   * 字段校验规则
   */
  validateType?: FieldValidateTypeEnum | null
  validateTypes?: string[] | null
  /**
   * 省市区字段 - 是否使用详细地址
   */
  enableDetailAddress?: boolean | null
  /**
   * 类型为单选或者多选时填写，为选项值
   */
  options?: string[]
  /**
   * 字段的描述
   */
  description?: string
  /**
   * 字段是否必填
   */
  required?: boolean | null

  constructor(props: TableFieldInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      name: _name,
      label: _label,
      type: _type,
      optionSource: _optionSource,
      validateType: _validateType,
      validateTypes: _validateTypes,
      enableDetailAddress: _enableDetailAddress,
      options: _options,
      description: _description,
      required: _required,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.name = _name
    this.label = _label
    this.type = _type
    this.optionSource = _optionSource
    this.validateType = _validateType
    this.validateTypes = _validateTypes
    this.enableDetailAddress = _enableDetailAddress
    this.options = _options
    this.description = _description
    this.required = _required
  }

  static propKeys = [
    'name',
    'label',
    'type',
    'optionSource',
    'validateType',
    'validateTypes',
    'enableDetailAddress',
    'options',
    'description',
    'required',
  ]
}
