/**
 * @file passport helper
 */

import { AxiosResponse, getPureAxios } from '@seiue/axios'
import { env } from '@seiue/env'
import { stringifyURLQuery, parseURLQuery } from '@seiue/util'

import { SSOProvider } from 'packages/features/sessions/utils/types'

const passportHost = env('SERVER_PASSPORT')
let payload: { [key: string]: any } = {}

/**
 * @deprecated passport 不会再抛出这个错误，等待 preview 和 go 迁移完毕后，移除本错误
 *
 * 表示 passport 已经跳往登录页，应用流程无需再继续。
 */
export const PASSPORT_REDIRECTING = new Error()

export const PASSPORT_SETUP_FAILED = new Error()

const onLogoutAndSwitched = ({
  origin,
  data: { event },
}: {
  origin: string
  data: { event: string }
}) => {
  if (origin === env('SERVER_PASSPORT')) {
    if (event === 'logout' || event === 'switched') {
      // 已知现象：Chrome 中的非当前/不活跃 tab 经常不显示 alert，就会直接刷新

      // eslint-disable-next-line
      window.alert('你已在其他页面退出登录或切换账号，我们将为你刷新当前页面。')

      window.location.reload()
    }
  }
}

/**
 * 当前会话信息
 *
 * @class Session
 */
export const passport = {
  // chalk 2.0 store [schoolId, reflectionId, roleId] for each uid
  getCachedRid(uid: number) {
    return +(payload[uid]?.split('_')[1] || 0)
  },

  // @private 获取缓存信息
  getPassportPayload() {
    return payload
  },

  setSessionCache(uid: number, schoolId: number, rid: number, role: number) {
    // return if passport not set up
    if (!this._frame) return null

    return this.setShared(`${uid}`, `${schoolId}_${rid}_${role}`)
  },

  buildHookUrl(params = {}) {
    const { protocol, host } = window.location
    const queryStr = stringifyURLQuery({
      host: `${protocol}//${host}`,
      ...params,
    })

    return `${passportHost}/session/hooks?${queryStr}`
  },

  createIframeForHook() {
    const frame = document.createElement('iframe')

    frame.setAttribute('src', this.buildHookUrl())

    frame.style.visibility = 'hidden'
    frame.style.position = 'absolute'
    frame.style.left = '-9999px'
    frame.style.top = '-9999px'

    document.body.appendChild(frame)

    return frame
  },

  // TODO need a timeout setting in case passport is not responding
  getPromiseOfIframeEvent(event: string) {
    return new Promise(resolve => {
      const onLoaded = ({
        origin,
        data,
      }: {
        origin: string
        data: { event: string; payload: any }
      }) => {
        if (origin !== passportHost || data.event !== event) return
        payload = { ...data.payload }
        resolve(data.payload)
        window.removeEventListener('message', onLoaded)
      }

      window.addEventListener('message', onLoaded, false)
    })
  },

  setShared(key: string, value: string) {
    if (typeof key !== 'string' || typeof value !== 'string') {
      throw new Error('The key and value parameter must be string')
    }

    if (this._frame) {
      const promise = this.getPromiseOfIframeEvent('succeed')
      const params = {
        m: 'set',
        key,
        value,
      }

      payload[key] = value
      this._frame.contentWindow.location.replace(this.buildHookUrl(params))

      return promise
    }

    return null
  },

  deleteShared(key: string) {
    if (typeof key !== 'string') {
      throw new Error('The key parameter must be string')
    }

    if (this._frame) {
      const promise = this.getPromiseOfIframeEvent('succeed')

      this._frame.contentWindow.location.replace(
        this.buildHookUrl({ m: 'del', key }),
      )

      return promise
    }

    return null
  },

  /**
   * setup passport iframe hook to receive session changes
   *
   * @return {Promise} a promise that resolves with user id
   */
  async setup(): Promise<{ uid: number; rid: number }> {
    // there can be only one passport iframe
    if (this._frame) this.teardown()

    this._frame = this.createIframeForHook()

    window.addEventListener('message', onLogoutAndSwitched, false)

    const passportData = await this.getPromiseOfIframeEvent('loaded')

    const passportUid = +passportData.active_user

    if (!passportUid) {
      throw PASSPORT_SETUP_FAILED
    }

    return {
      uid: passportUid,
      rid: +passportData.active_reflection || this.getCachedRid(passportUid),
    }
  },

  isSetUp() {
    return !!this._frame
  },

  teardown() {
    if (this._frame) {
      document.body.removeChild(this._frame)
      delete this._frame
    }

    window.removeEventListener('message', onLogoutAndSwitched)
  },

  /**
   * get passport login url
   *
   * @param options - options
   * @param options.schoolId - 登出后，指定访问的 schoolId
   * @param options.setRedirect - 设置重定向
   */
  gotoLoginPage(options: {
    schoolId?: number | string
    setRedirect?: boolean
  }) {
    // 未传入 schoolId 则尝试从 url query 中获取
    const schoolId =
      options.schoolId || parseURLQuery(window.location.search)['schoolId']

    // 新版 passport 页面几乎都是基于 school_id 下的个性化学校登录页，所以没有 school_id 的情况下需要去学校选择页面
    if (!schoolId) {
      window.location.href = passportHost
      return
    }

    const baseQuery = {
      force: 1,
      school_id: schoolId,
      type: 'account',
    }

    window.location.href = `${passportHost}/login?${stringifyURLQuery(
      options.setRedirect
        ? {
            redirect_uri: window.location.href,
            ...baseQuery,
          }
        : baseQuery,
    )}`
  },

  /**
   * 退出登录
   *
   * @param schoolId - 学校 ID, 为空时跳转选择学校页面
   * @param setPassportRedirect - 携带返回地址
   * @param ssoProvider - 单点登录提供商
   */
  logout(
    schoolId: number | undefined = undefined,
    setPassportRedirect = false,
    ssoProvider?: SSOProvider,
  ) {
    const query = {} as Record<string, string | number>

    if (schoolId) {
      query['school_id'] = schoolId
    }

    if (setPassportRedirect) {
      query['redirect_uri'] = window.location.href
    }

    if (ssoProvider) {
      query['sso_provider'] = ssoProvider
    }

    window.location.href = `${passportHost}/logout?${stringifyURLQuery(query)}`
  },

  gotoSelectSchoolPage() {
    window.location.href = passportHost
  },

  generateCode(identity: string): Promise<
    AxiosResponse<{
      ok: boolean
      reminderId?: string
      info?: string
    }>
  > {
    const url = `${passportHost}/bindings/generate-code`

    return getPureAxios().post(url, { identity }, { withCredentials: true })
  },

  bindPhone({ reminderId, code }: { reminderId: string; code: string }) {
    const url = `${passportHost}/bindings/check-code`

    return getPureAxios().post(
      url,
      { id: reminderId, secret: code },
      { withCredentials: true },
    )
  },

  sendEmail({
    email,
    redirectUri,
    password,
    account,
    schoolId,
  }: {
    email: string
    redirectUri: string
    password: string
    account: string
    schoolId: number
  }) {
    const url = `${passportHost}/bindings/send-email`

    return getPureAxios().post(
      url,
      {
        email,
        redirect_uri: redirectUri,
        password,
        account,
        school_id: schoolId,
      },
      { withCredentials: true },
    )
  },
}
