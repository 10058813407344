/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import {
  ReflectionWithUser,
  ReflectionWithUserInterface,
} from './extends/reflection-with-user'
import type { LoadStudentByRelationRidQuery } from './load-student-by-relation-rid-query'

const buildConfig = createConfigBuilder('chalk')

export const reflectionApi$loadStudentByRelationRid = {
  config(
    id: number,
    reflectionId: number,
    loadStudentByRelationRidQuery?: LoadStudentByRelationRidQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/reflection/students/{id}/rid/{reflectionId}',
      {
        id,
        reflectionId,
      },
      loadStudentByRelationRidQuery,
    )
  },

  /**
   * @summary 根据档案群组权限，获取学生个人信息
   * @param id
   * @param reflectionId
   * @param [loadStudentByRelationRidQuery]
   * @param [reflectionQuery.expand]
   * @param [options]
   * @return AxiosResponsePromise<ReflectionWithUser>
   */

  api<const TQuery extends LoadStudentByRelationRidQuery>(
    id: number,
    reflectionId: number,
    loadStudentByRelationRidQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<ReflectionWithUser, TQuery['expand']>> {
    return axios(
      reflectionApi$loadStudentByRelationRid.config(
        id,
        reflectionId,
        loadStudentByRelationRidQuery,
        options,
      ),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new ReflectionWithUser(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends LoadStudentByRelationRidQuery,
    TSelected = Expand<ReflectionWithUser, TQuery['expand']>,
  >(
    {
      id,
      reflectionId,
      query,
    }: {
      id: number
      reflectionId: number
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<ReflectionWithUser, TQuery['expand']>,
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<ReflectionWithUser, TQuery['expand']>, TSelected>(
      reflectionApi$loadStudentByRelationRid.api,
      'Reflection.loadStudentByRelationRid',
      queryOptions,
      id,
      reflectionId,
      query,
    )
  },
}

// @ts-ignore
reflectionApi$loadStudentByRelationRid.api._name_ = `Reflection.loadStudentByRelationRid`
