import { FieldTypeEnum } from './field-type-enum'
import { TableField, TableFieldInterface } from './table-field'
import { FieldOptionSourceEnum } from './field-option-source-enum'
import { FieldTagEnum } from './field-tag-enum'

export interface FieldInterface {
  /**
   * 所属学校id
   */
  schoolId: number
  /**
   * 字段名称
   */
  name: string
  /**
   * 字段描述
   */
  label: string
  /**
   * 自定义字段类型（文本，单选，多选，数字，时间, 日期）
   */
  type: FieldTypeEnum
  tableFields?: TableFieldInterface[]
  /**
   * 类型为单选或者多选时填写，为选项值
   */
  options?: string[]
  /**
   * 字段的描述
   */
  description?: string
  /**
   * 是否是关联信息
   */
  isRelation: boolean
  /**
   * 省市区字段 - 是否使用详细地址
   */
  enableDetailAddress?: boolean | null
  /**
   * 自定义字段子类型
   */
  optionSource?: FieldOptionSourceEnum | null
  /**
   * 字段校验规则
   */
  validateTypes?: string[] | null
  /**
   * 字段是否必填
   */
  required?: boolean | null
  /**
   * 当字段类型为单选或者多选时，使用term的值来作为选项
   */
  termType?: string | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 字段分类（关联字段，自定义字段，默认字段）
   */
  tag: FieldTagEnum
  /**
   * 所属领域
   */
  domain: string
}

export class Field implements FieldInterface {
  /**
   * 所属学校id
   */
  schoolId: number
  /**
   * 字段名称
   */
  name: string
  /**
   * 字段描述
   */
  label: string
  /**
   * 自定义字段类型（文本，单选，多选，数字，时间, 日期）
   */
  type: FieldTypeEnum
  tableFields?: TableField[]
  /**
   * 类型为单选或者多选时填写，为选项值
   */
  options?: string[]
  /**
   * 字段的描述
   */
  description?: string
  /**
   * 是否是关联信息
   */
  isRelation: boolean
  /**
   * 省市区字段 - 是否使用详细地址
   */
  enableDetailAddress?: boolean | null
  /**
   * 自定义字段子类型
   */
  optionSource?: FieldOptionSourceEnum | null
  /**
   * 字段校验规则
   */
  validateTypes?: string[] | null
  /**
   * 字段是否必填
   */
  required?: boolean | null
  /**
   * 当字段类型为单选或者多选时，使用term的值来作为选项
   */
  termType?: string | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 字段分类（关联字段，自定义字段，默认字段）
   */
  tag: FieldTagEnum
  /**
   * 所属领域
   */
  domain: string

  constructor(props: FieldInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      schoolId: _schoolId,
      name: _name,
      label: _label,
      type: _type,
      tableFields: _tableFields,
      options: _options,
      description: _description,
      isRelation: _isRelation,
      enableDetailAddress: _enableDetailAddress,
      optionSource: _optionSource,
      validateTypes: _validateTypes,
      required: _required,
      termType: _termType,
      id: _id,
      tag: _tag,
      domain: _domain,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.schoolId = _schoolId
    this.name = _name
    this.label = _label
    this.type = _type
    this.tableFields = _tableFields
      ? _tableFields.map(i => new TableField(i))
      : _tableFields
    this.options = _options
    this.description = _description
    this.isRelation = _isRelation
    this.enableDetailAddress = _enableDetailAddress
    this.optionSource = _optionSource
    this.validateTypes = _validateTypes
    this.required = _required
    this.termType = _termType
    this.id = _id
    this.tag = _tag
    this.domain = _domain
  }

  static propKeys = [
    'schoolId',
    'name',
    'label',
    'type',
    'tableFields',
    'options',
    'description',
    'isRelation',
    'enableDetailAddress',
    'optionSource',
    'validateTypes',
    'required',
    'termType',
    'id',
    'tag',
    'domain',
  ]
}
