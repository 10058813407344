import { $ct } from 'packages/locale'
import { SchoolPlugin } from 'packages/sdks-next/chalk'

import { EnabledPlugin } from './types'

/**
 * 检查一组插件中是否有当前用户是管理员的插件
 *
 * @param plugins - 一组插件
 * @returns 是否
 */
export const hasManageablePlugin = (plugins: EnabledPlugin[]) => {
  return plugins.some(p => p.isAdmin)
}

/**
 * 检查一组插件中是否有当前用户在使用范围中的插件
 *
 * @param plugins - 一组插件
 * @returns 是否
 */
export const hasUsablePlugin = (plugins: EnabledPlugin[]) => {
  return plugins.some(p => p.isVisible)
}

/**
 * 获取插件实例的显示名称.
 *
 * @param plugin - 插件
 * @returns 显示名称
 */
export const getPluginLabel = (plugin: EnabledPlugin) => {
  return $ct(plugin.label, 'plugin')
}

/**
 * 获取插件的图标.
 * 子插件直接获取自己的图标, 非子插件获取自己对应的 apollo 插件的最新图标 (因为现在 apollo 上更新插件图标还不会同步给学校已安装的插件实例).
 *
 * @param plugin - 插件
 * @returns 插件图标
 */
export const getPluginIcon = (
  plugin: Pick<EnabledPlugin, 'icon' | 'plugin' | 'parentId'>,
) => {
  return plugin.parentId ? plugin.icon : plugin.plugin.icon
}

/**
 * 查找当前用户可管理或可使用的子插件
 *
 * @param plugins - 一组插件
 * @param name - 插件名
 * @returns 插件列表
 */
export const findUsableOrManageableChildPlugins = (
  plugins: EnabledPlugin[],
  name: string,
) => {
  return plugins.filter(
    p => p.pluginName === name && !!p.parentId && (p.isVisible || p.isAdmin),
  )
}

/**
 * 判断是否为某平台应用插件
 *
 * @param plugin - 插件
 * @param platform - 平台
 * @returns boolean
 */
export const isPluginForPlatform = (
  plugin: EnabledPlugin | SchoolPlugin,
  platform: 'web' | 'mobile',
) => {
  return plugin.plugin?.platforms?.includes(platform)
}
