import { WeworkStatusEnum } from './wework-status-enum'

export interface WeworkInterface {
  reflectionId: number
  weworkName?: string | null
  status: WeworkStatusEnum
  reason?: string | null
  schoolName?: string | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Wework implements WeworkInterface {
  reflectionId: number
  weworkName?: string | null
  status: WeworkStatusEnum
  reason?: string | null
  schoolName?: string | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: WeworkInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      reflectionId: _reflectionId,
      weworkName: _weworkName,
      status: _status,
      reason: _reason,
      schoolName: _schoolName,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.reflectionId = _reflectionId
    this.weworkName = _weworkName
    this.status = _status
    this.reason = _reason
    this.schoolName = _schoolName
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'reflectionId',
    'weworkName',
    'status',
    'reason',
    'schoolName',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}
