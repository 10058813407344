import { lazy } from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { ModalRouteNode } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

/**
 * CopilotDemo 后台路由
 * 从 Copilot 路由中复制过来，后续这两个插件会合并
 */
export const routes = [
  {
    path: 'admin/plugin/copilot-demo/stats',
    name: 'AdminCopilotDemoStats',
    component: lazy(() =>
      import('../../copilot/pages/Admin/Stats').then(m => ({
        default: m.CopilotAdminStats,
      })),
    ),
    getTitle: () => $t('数据统计'),
    module: [PluginCategoryEnum.通用工具, PluginNameEnum.CopilotDemo],
  },
  {
    path: 'admin/plugin/copilot-demo/knowledge-bases',
    name: 'AdminCopilotDemoKnowledgeBases',
    component: lazy(() =>
      import('../../copilot/pages/Admin/KnowledgeBases').then(m => ({
        default: m.KnowledgeBaseList,
      })),
    ),
    getTitle: () => $t('知识库'),
    module: [PluginCategoryEnum.通用工具, PluginNameEnum.CopilotDemo],
  },
]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'PluginCopilotDemoKnowledgeBaseDetailModal',
    getTitle: () => $t('知识库详情'),
    component: lazy(() =>
      import('../../copilot/pages/Admin/KnowledgeBaseDetailModal').then(m => ({
        default: m.KnowledgeBaseDetailModal,
      })),
    ),
  },
]
