import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminGzArchiveMenu } from './menus'
import { getGzarchiveRoutes } from './route'

export const initGZArchive: InitPluginFeature = {
  name: PluginNameEnum.GzArchive,
  init: () => ({
    slots: {
      userTableHelper: () =>
        import('./slots/data-slots').then(m => m.adminStudentTableHelper),
      scopesBoardMetasWithoutDomainSlots: () =>
        import('./slots/data-slots').then(
          m => m.scopesBoardMetasWithoutDomainSlots,
        ),
    },
    routes: getGzarchiveRoutes(),
    menus: [adminGzArchiveMenu],
  }),
}
