/**
 * @file 深圳中学定制补考规则
 */

import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

export const initSZCustomResitRule: InitPluginFeature = {
  name: PluginNameEnum.SZCustomResitRule,
  init: () => ({
    slots: {
      resitGradeInputTableHelper: () =>
        import('./slots/resit-grade-input-table-helper').then(
          m => m.resitGradeInputTableHelper,
        ),
      classGradeVisibilityOverride: () =>
        import('./slots/class-grade-visibility-override').then(
          m => m.ClassGradeVisibilityOverride,
        ),
    },
  }),
}
