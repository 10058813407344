import { PluginNameEnum } from 'packages/plugins/types'

import { InitPluginFeature } from '@/plugins/types'

import { adminCopilotMenu } from './menus'
import { modalRoutes, routes } from './routes'
import { widgets } from './widgets'

/**
 * Copilot，正式版
 */
export const initCopilot: InitPluginFeature = {
  name: PluginNameEnum.Copilot,
  init: () => {
    return {
      widgets,
      routes,
      modalRoutes,
      menus: [adminCopilotMenu],
    }
  },
}
