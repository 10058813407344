import { ExamTypeEnum } from './exam-type-enum'
import { ExamStatusEnum } from './exam-status-enum'
import { ExamScoringTypeEnum } from './exam-scoring-type-enum'
import { Semester, SemesterInterface } from './semester'
import { Term, TermInterface } from './extends/term'
import { ExamStatsData, ExamStatsDataInterface } from './exam-stats-data'

export interface ExamWithoutSubjectInterface {
  semesterId: number
  name: string
  /**
   * 考试类型
   */
  examType: ExamTypeEnum
  /**
   * 考试标签，后续改为 tag_ids
   */
  typeId: number | null
  graduatesInId: number | null
  status: ExamStatusEnum
  stage?: number | null
  scoringType?: ExamScoringTypeEnum | null
  /**
   * expand 已录入的分数数量
   */
  existedScoresCount?: number
  /**
   * expand 应该有的分数数量，按照 relations 统计
   */
  allScoresCount?: number
  /**
   * expand 学期
   */
  semester?: SemesterInterface
  /**
   * expand 考试类型
   */
  type?: TermInterface | null
  /**
   * expand 届别
   */
  graduatesIn?: TermInterface | null
  /**
   * 考试统计相关设置数据
   */
  statsData?: ExamStatsDataInterface
  /**
   * 发布时间
   */
  publishedAt?: string | null
  studentDisplay?: string[] | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class ExamWithoutSubject implements ExamWithoutSubjectInterface {
  semesterId: number
  name: string
  /**
   * 考试类型
   */
  examType: ExamTypeEnum
  /**
   * 考试标签，后续改为 tag_ids
   */
  typeId: number | null
  graduatesInId: number | null
  status: ExamStatusEnum
  stage?: number | null
  scoringType?: ExamScoringTypeEnum | null
  /**
   * expand 已录入的分数数量
   */
  existedScoresCount?: number
  /**
   * expand 应该有的分数数量，按照 relations 统计
   */
  allScoresCount?: number
  /**
   * expand 学期
   */
  semester?: Semester
  /**
   * expand 考试类型
   */
  type?: Term | null
  /**
   * expand 届别
   */
  graduatesIn?: Term | null
  /**
   * 考试统计相关设置数据
   */
  statsData?: ExamStatsData
  /**
   * 发布时间
   */
  publishedAt?: string | null
  studentDisplay?: string[] | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: ExamWithoutSubjectInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      semesterId: _semesterId,
      name: _name,
      examType: _examType,
      typeId: _typeId,
      graduatesInId: _graduatesInId,
      status: _status,
      stage: _stage,
      scoringType: _scoringType,
      existedScoresCount: _existedScoresCount,
      allScoresCount: _allScoresCount,
      semester: _semester,
      type: _type,
      graduatesIn: _graduatesIn,
      statsData: _statsData,
      publishedAt: _publishedAt,
      studentDisplay: _studentDisplay,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.semesterId = _semesterId
    this.name = _name
    this.examType = _examType
    this.typeId = _typeId
    this.graduatesInId = _graduatesInId
    this.status = _status
    this.stage = _stage
    this.scoringType = _scoringType
    this.existedScoresCount = _existedScoresCount
    this.allScoresCount = _allScoresCount
    this.semester = _semester ? new Semester(_semester) : _semester
    this.type = _type ? new Term(_type) : _type
    this.graduatesIn = _graduatesIn ? new Term(_graduatesIn) : _graduatesIn
    this.statsData = _statsData ? new ExamStatsData(_statsData) : _statsData
    this.publishedAt = _publishedAt
    this.studentDisplay = _studentDisplay
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'semesterId',
    'name',
    'examType',
    'typeId',
    'graduatesInId',
    'status',
    'stage',
    'scoringType',
    'existedScoresCount',
    'allScoresCount',
    'semester',
    'type',
    'graduatesIn',
    'statsData',
    'publishedAt',
    'studentDisplay',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}
