/**
 * @file 审批服务数据处理 utils
 */

import { camelCase, compact, flatten, upperFirst } from '@seiue/util'
import { useMemo } from 'react'

import { useCurrentReflection } from 'packages/features/sessions/utils/data'
import {
  FlowFieldValue,
  WorkflowBizType,
  WorkflowBizTypeCapitalized,
} from 'packages/features/workflows/types'
import { Expand } from 'packages/sdks-next'
import {
  Field,
  Flow,
  FlowStageStatusEnum,
  FlowNodeStatusEnum,
  NodeTypeEnum,
  FlowNode,
  FieldTypeEnum,
  workflowApi$queryReflections,
} from 'packages/sdks-next/form'

import { getMyCurrentFieldPerms } from '../components/WorkflowBuilder/components/NodeSettingsForm/FieldPermissions/utils'

import { LoadFlowDataType } from './apis'
import { getFlowFieldTextValue } from './views'

/**
 * 判断当前用户是否未读审批单（抄送节点有已读/未读）
 *
 * @param flow - 审批单
 * @returns 是否未读审批单
 */
export const useUnreadFlow = (
  flow: Expand<Flow, ['nodes', ['nodes', 'stages']]> | null,
) => {
  const currentReflection = useCurrentReflection()

  return !!flow?.nodes.some(
    node =>
      node.nodeType === NodeTypeEnum.Cc &&
      node.stages?.some(
        stage =>
          stage.status === FlowStageStatusEnum.Pending &&
          stage.reflectionId === currentReflection.id,
      ),
  )
}

/**
 * 获取与我相关的所有已到达节点
 *
 * @param flowNodes - 审批单节点
 * @returns 与我相关的所有已到达节点
 */
export const useMyNoWaitingFlowNode = (
  flowNodes?: LoadFlowDataType['nodes'] | null,
) => {
  const currentReflection = useCurrentReflection()

  if (!flowNodes) return []

  return flowNodes.filter(
    node =>
      node.stages?.length &&
      node.status !== FlowNodeStatusEnum.Waiting &&
      node.stages?.some(stage => stage.reflectionId === currentReflection.id),
  )
}

/**
 * 获取对应审批业务的业务类型
 *
 * @param extensionType - 审批单扩展类型
 * @returns 对应审批业务的业务类型
 */
export const getWorkflowBizType = (
  extensionType?: string | null,
): WorkflowBizType =>
  (extensionType?.split(':')?.[1] || 'approval') as WorkflowBizType

/**
 * 获取对应审批业务的创建/修改审批插件路由
 *
 * @param param0 - 参数
 * @param param0.pluginId - 插件 id
 * @param param0.parentPluginId - 父插件 id
 * @param param0.extensionType - 审批单扩展类型
 * @returns 对应审批业务的创建/修改审批插件路由
 */
export const getWorkflowSettingsModalParams = ({
  pluginId,
  parentPluginId,
  extensionType,
}: {
  pluginId?: number
  parentPluginId?: number
  extensionType?: string | null
}) => {
  const workflowType = upperFirst(
    camelCase(getWorkflowBizType(extensionType)),
  ) as WorkflowBizTypeCapitalized

  const modalName = `Plugin.${workflowType}SettingsModal` as const

  return [
    modalName,
    {
      // 插件 id
      id: pluginId,
      // 父插件 id
      parentId: parentPluginId,
      // 审批流业务扩展类型
      extensionType,
    },
  ] as const
}

/**
 * 获取预览表单值
 *
 * @param param0 - 参数
 * @param param0.fields - 表单题目
 * @param param0.fieldValues - 表单值
 * @param param0.formatBizFieldToTextValue - 格式化业务预览字段
 * @param param0.formatBizFieldLabel - 格式化业务字段 label
 * @returns 预览表单值
 */
export const getPreviewFields = ({
  fields,
  fieldValues,
  formatBizFieldToTextValue,
  formatBizFieldLabel,
}: {
  fields: Field[]
  fieldValues: FlowFieldValue[]

  // 格式化业务预览字段
  formatBizFieldToTextValue?: (
    flowField: FlowFieldValue,
    field: Field,
    fieldValues: FlowFieldValue[],
  ) => string

  // 格式化业务字段 label
  formatBizFieldLabel?: (args: {
    field: Field
    fieldValues: FlowFieldValue[]
  }) => string
}) =>
  fields
    .map(field => ({
      label: formatBizFieldLabel?.({ field, fieldValues }) || field.label,
      value: getFlowFieldTextValue({
        field,
        fieldValues,
        formatBizFieldToTextValue,
      }),
    }))
    .filter(field => !!field.value)

/**
 * 获取用于展示的审批单字段
 *
 * @param param - Argument Object
 * @param param.flow - 审批单
 * @param param.flowNodes - 相关审批单节点
 * @returns 用于展示的审批单字段
 */
export const useFlowFieldsForAttributes = ({
  flow,
  flowNodes,
}: {
  flow?: LoadFlowDataType | null
  flowNodes?: FlowNode[]
}) => {
  const { fields, schoolPluginId } = flow?.workflow || {}

  const fieldValues = useMemo(
    () => (flow?.fieldValues || []) as FlowFieldValue[],
    [flow?.fieldValues],
  )

  const selectedFields: number[] = compact(
    flatten(
      fields
        ?.filter(field => field.type === FieldTypeEnum.ReflectionPicker)
        .map(field => {
          const targetValue = fieldValues.find(
            fv => fv.fieldName === field.name,
          )

          if (!targetValue) return null

          return targetValue.value || []
        }),
    ),
  )

  // 获取人员选择器选中的 reflection
  const { data: selectedReflections } = workflowApi$queryReflections.useApi(
    {
      schoolPluginId,
      idIn: selectedFields.join(','),
      flowId: flow?.id,
    },
    {
      disable: !schoolPluginId || !selectedFields.length,
    },
  )

  const filterFields = useMemo(() => {
    if (!fields || !flowNodes || !flow) return []

    const { fieldPermsMap } = getMyCurrentFieldPerms(flow.workflow, flowNodes)

    return fields.filter(
      field =>
        ![FieldTypeEnum.Paragraph, FieldTypeEnum.Hidden].includes(field.type) &&
        fieldPermsMap[field.name]?.readable,
    )
  }, [fields, flow, flowNodes])

  return {
    fields: filterFields,
    fieldValues,
    selectedReflections,
  }
}
