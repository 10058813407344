import { useAtom } from 'jotai'
import React, { useMemo } from 'react'

import {
  ScopeDomain,
  useHasManageablePlugin,
} from 'packages/feature-utils/plugins'
import {
  GroupPermissionTypeEnum,
  useGroupOmitSomeError,
} from 'packages/features/groups'
import { useSecurityFields } from 'packages/features/reflections/hooks/security'
import { useIsManagedResource } from 'packages/features/roles'
import { useCurrentReflection } from 'packages/features/sessions'
import { PluginNameEnum } from 'packages/plugins/types'
import {
  EnhancerEnum,
  groupApi$getPermissionsByReflection,
  GroupTypeEnum,
  LoadStudentQuery,
  PermissionNameEnum,
  reflectionApi$loadStudent,
  reflectionApi$loadStudentByRelationRid,
  reflectionProfileApi$loadReflectionProfile,
  roleApi$queryManagedScopes,
  RoleEnum,
  ScopeEnhancerEnum,
  SecuredReflection,
} from 'packages/sdks-next/chalk'

import { SecurityGroupNameEnum } from '../fields'
import {
  loadGroupPermissionsForReflection,
  useGetGroupByBiz,
} from '../groups/apis'

import { managedStudentScopesAtom } from './atoms'

/**
 * 检查当前登录用户是否有对学生的档案访问权限
 * 这个接口会查询
 * 1. 用户管理权限
 * 2. 和学生的关系带来的档案权限，比如班主任、群组管理员，家长乃至学生本人
 *
 * @returns 判断是否有档案权限的函数
 */
export const useHasStudentProfilePermissionFn = () => {
  // 当前登录用户所拥有的学生管理范围
  const [managedStudentScopes, setManagedStudentScopes] = useAtom(
    managedStudentScopesAtom,
  )

  const currentRid = useCurrentReflection().id

  return React.useCallback(
    /**
     * 是否有对学生的档案访问权限
     *
     * @param rid - 要查看的学生 id
     * @param permission - 档案权限名称
     * @returns 是否
     */
    async (rid: number, permission: GroupPermissionTypeEnum) => {
      let innerScopes = managedStudentScopes

      if (!innerScopes) {
        const { data: scopes } = await roleApi$queryManagedScopes.api(
          currentRid,
          ScopeDomain.Reflection,
          {
            type: EnhancerEnum.StudentScope,
            permission: PermissionNameEnum.CoreUserRead,
          },
        )

        innerScopes = scopes
        setManagedStudentScopes(scopes)
      }

      // 学生是否在管理范围中
      const isStudentInScope = !!innerScopes?.some(scope =>
        scope.resourceIds?.includes(rid),
      )

      if (isStudentInScope) {
        return true
      }

      // 学生不在管理范围，从档案权限中找
      const groupPermissions = await loadGroupPermissionsForReflection({
        rid: currentRid,
        type: GroupTypeEnum.RelationGroup,
        bizId: `${rid}`,
      })

      return groupPermissions.some(perm => perm === permission)
    },
    [currentRid, managedStudentScopes, setManagedStudentScopes],
  )
}

/**
 * 与上面接口类似，但是固定了学生 id，仅支持权限的灵活查找，这样返回的函数无需异步
 *
 * 检查当前登录用户是否有对学生的档案访问权限
 * 这个接口会查询
 * 1. 用户管理权限
 * 2. 和学生的关系带来的档案权限，比如班主任、群组管理员，家长乃至学生本人
 *
 * @param studentId - 学生 id
 * @param disable - 是否禁用
 * @returns 判断是否有档案权限的函数
 */
export const useHasProfilePermissionInStudentFn = (
  studentId: number,
  disable?: boolean,
) => {
  // 当前登录用户所拥有的学生管理范围
  const currentRid = useCurrentReflection().id

  const { data: managedStudentScopes, loading: loadingManagedStudentScopes } =
    roleApi$queryManagedScopes.useApi(
      {
        id: currentRid,
        domain: ScopeDomain.Reflection,
        query: {
          type: EnhancerEnum.StudentScope,
          permission: PermissionNameEnum.CoreUserRead,
        },
      },
      {
        disable,
      },
    )

  const isStudentInScope = !!managedStudentScopes?.some(scope =>
    scope.resourceIds?.includes(studentId),
  )

  // 学生不在管理范围，从档案权限中找
  const { data: relationGroup } = useGetGroupByBiz(
    {
      type: GroupTypeEnum.RelationGroup,
      bizId: `${studentId}`,
    },
    {
      omitNotPerm: true,
      omitNotFound: true,
      disable: isStudentInScope || loadingManagedStudentScopes || disable,
    },
  )

  const { data: groupPermissions } = groupApi$getPermissionsByReflection.useApi(
    {
      groupId: Number(relationGroup?.id),
      rid: currentRid,
    },
    {
      disable: !relationGroup?.id,
    },
  )

  return React.useCallback(
    /**
     * 是否有对学生的档案访问权限
     *
     * @param permission - 档案权限名称，甚至包含学籍表
     * @returns 是否
     */
    (permission: GroupPermissionTypeEnum | string) => {
      if (isStudentInScope) {
        return true
      }

      return groupPermissions?.some(perm => perm === permission) ?? false
    },
    [groupPermissions, isStudentInScope],
  )
}

const useIsManagedStudent = (studentId: number | null) => {
  return useIsManagedResource(
    {
      domain: ScopeDomain.Reflection,
      permission: PermissionNameEnum.CoreUserRead,
      scopeName: ScopeEnhancerEnum.StudentScope,
      staleTime: 3 * 60,
    },
    studentId || undefined,
  )
}

/**
 * 获取当前用户对学生的档案访问权限
 *
 * @param studentId - 学生 id
 * @returns [权限类型, 加载状态]
 */
export const useReadStudentProfilePermissionType = (studentId: number) => {
  const [isCoreReadAdmin, loadingPermission] = useIsManagedStudent(studentId)

  // 是否是学籍管理员
  const isStudentProfileManager = useHasManageablePlugin(
    PluginNameEnum.StudentStatusProfile,
  )

  const hasProfilePermissionFn = useHasProfilePermissionInStudentFn(
    studentId,
    isCoreReadAdmin || loadingPermission || isStudentProfileManager,
  )

  let permissionType: 'core' | 'student-profile' | 'profile' | null = null

  if (isCoreReadAdmin) {
    permissionType = 'core'
  } else if (isStudentProfileManager) {
    permissionType = 'student-profile'
  } else {
    permissionType = hasProfilePermissionFn(
      GroupPermissionTypeEnum.PersonalBase,
    )
      ? 'profile'
      : null
  }

  return {
    permissionType,
    loading: loadingPermission,
    hasProfilePermissionFn,
  }
}

/**
 * 基于个人权限获取学生的个人信息
 *
 * @param studentId - 学生 id
 * @param query - 查询参数
 * @returns 学生的个人信息
 */
export const useLoadStudentByPermission = (
  studentId: number,
  query?: LoadStudentQuery,
) => {
  const reflection = useCurrentReflection()

  const { permissionType, loading: loadingPermission } =
    useReadStudentProfilePermissionType(studentId)

  const isCoreReadAdmin = permissionType === 'core'
  const isStudentProfileManager = permissionType === 'student-profile'
  const isProfile = permissionType === 'profile'

  // 用户管理
  const { data: studentByCoreRead, loading: loadingStudentByCoreRead } =
    reflectionApi$loadStudent.useApi(
      {
        id: studentId,
        query,
      },
      {
        disable: !isCoreReadAdmin || loadingPermission,
      },
    )

  const { data: studentByProfile, loading: loadingStudentByProfile } =
    reflectionProfileApi$loadReflectionProfile.useApi(
      {
        role: RoleEnum.Student,
        policy: PluginNameEnum.StudentStatusProfile,
        id: studentId,
        // @ts-expect-error 类型不匹配
        query,
      },
      {
        disable: !isStudentProfileManager || loadingPermission,
      },
    )

  // 关系组兜底查询
  const { data: studentByRelation, loading: loadingStudentByRelation } =
    reflectionApi$loadStudentByRelationRid.useApi(
      {
        id: studentId,
        reflectionId: reflection.id,
        query,
      },
      {
        disable: !isProfile || loadingPermission,
      },
    )

  let student: SecuredReflection | null = null
  let loading = false

  if (isCoreReadAdmin) {
    student = studentByCoreRead
    loading = loadingStudentByCoreRead
  } else if (isStudentProfileManager) {
    // @ts-expect-error 类型不匹配
    student = studentByProfile
    loading = loadingStudentByProfile
  } else {
    student = studentByRelation
    loading = loadingStudentByRelation
  }

  return {
    student,
    loading,
  }
}

/**
 * 获取当前用户对某个学生的字段查看权限，基于档案权限
 *
 * @param studentId - 学生 id
 * @param disable - 是否禁用
 * @returns - permissions
 */
export const useProfileRolePermission = (
  studentId: number,
  disable?: boolean,
) => {
  const [group, groupLoading] = useGroupOmitSomeError({
    type: GroupTypeEnum.RelationGroup,
    bizId: studentId,
    waitFor: !!studentId && !disable,
  })

  const { id: rid } = useCurrentReflection()
  const { data: permissions, loading } =
    groupApi$getPermissionsByReflection.useApi(
      {
        groupId: Number(group?.id),
        rid,
      },
      { disable: groupLoading || !group?.id || disable },
    )

  return [permissions, groupLoading || loading] as const
}

/**
 * 获取当前用户对某个学生的字段查看权限
 *
 * @param studentId - 学生 id
 * @param disable - 是否禁用
 * @returns - 字段列表
 */
export const useLoadStudentFieldsByPermission = (
  studentId: number,
  disable?: boolean,
) => {
  const [isCoreReadAdmin, loadingCore] = useIsManagedStudent(studentId)

  const [permissions, loadingPermission] = useProfileRolePermission(
    studentId,
    disable || loadingCore || isCoreReadAdmin,
  )

  const securityScopes = useMemo(() => {
    if (isCoreReadAdmin) {
      return [
        SecurityGroupNameEnum.Base,
        SecurityGroupNameEnum.Manage,
        SecurityGroupNameEnum.Private,
      ]
    }

    const result: SecurityGroupNameEnum[] = []

    if (
      permissions?.find(
        permission => permission === GroupPermissionTypeEnum.PersonalBase,
      )
    ) {
      result.push(SecurityGroupNameEnum.Base)
    }

    if (
      permissions?.find(
        permission => permission === GroupPermissionTypeEnum.PersonalManage,
      )
    ) {
      result.push(SecurityGroupNameEnum.Manage)
    }

    if (
      permissions?.find(
        permission => permission === GroupPermissionTypeEnum.PersonalPrivacy,
      )
    ) {
      result.push(SecurityGroupNameEnum.Private)
    }

    return result
  }, [isCoreReadAdmin, permissions])

  const [fields, loadingFields] = useSecurityFields({
    role: RoleEnum.Student,
    securityScopes,
  })

  return [fields, loadingFields || loadingPermission] as const
}
