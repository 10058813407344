/**
 * @file 动态菜单
 */

import { atom } from 'jotai'

import {
  findEnabledPluginsAtom,
  getPluginIcon,
  hasManageableParentPluginAtom,
  hasUsablePluginAtom,
  PluginEnum,
} from 'packages/feature-utils/plugins'
import { getCurrentReflection } from 'packages/feature-utils/store'
import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { MenuType } from 'packages/route'
import {
  PermissionNameEnum,
  PluginCategoryEnum,
  RoleEnum,
} from 'packages/sdks-next/chalk'

export const adminQuestionnaireMenu = atom(get => {
  if (!get(hasManageableParentPluginAtom(PluginEnum.Questionnaire))) {
    return []
  }

  const enabledPlugin = get(
    findEnabledPluginsAtom(PluginEnum.Questionnaire),
  )?.[0]

  const shortcutIcon = enabledPlugin
    ? getPluginIcon(enabledPlugin)
    : 'Other+477cff'

  return [
    {
      prefix: [PluginCategoryEnum.通用工具],
      name: PluginNameEnum.Questionnaire,
      path: '/admin/plugin/questionnaire',
      icon: 'Questionnaire' as const,
      shortcutIcon,
      label: $t('问卷管理'),
      type: MenuType.AdminApps,
      sort: 720,
      subMenus: [
        {
          path: '/admin/plugin/questionnaire/list',
          label: $t('全部问卷'),
          icon: 'Questionnaire',
        },
        {
          path: '/admin/plugin/questionnaire/statistics',
          label: $t('问卷统计'),
          icon: 'Statistics',
        },
        {
          path: '/admin/plugin/questionnaire/templates',
          label: $t('问卷模板'),
          icon: 'Other',
        },
        {
          path: '/admin/plugin/questionnaire/permission-settings',
          label: $t('设置'),
          icon: 'Setting',
        },
      ],
    },
  ]
})

export const userQuestionnaireMenu = atom(get => {
  if (!get(hasUsablePluginAtom(PluginEnum.Questionnaire))) {
    return []
  }

  const currentReflection = getCurrentReflection()

  const subMenus = [
    {
      path: '/user/questionnaire/all',
      label: $t('问卷广场'),
      icon: 'Questionnaire',
    },
    {
      path: '/user/questionnaire/mine',
      label: $t('我填写的'),
      icon: 'Examination',
    },
    {
      path: '/user/questionnaire/from-me',
      label: $t('我发起的'),
      icon: 'Examination',
      permission: [PermissionNameEnum.QuestionnaireQuestionnaireCreate],
    },
  ]

  if (currentReflection.role === RoleEnum.Teacher) {
    subMenus.push({
      path: '/user/questionnaire/manage',
      label: $t('我管理的'),
      icon: 'Log',
    })
  }

  if (get(hasManageableParentPluginAtom(PluginNameEnum.Questionnaire))) {
    subMenus.push({
      path: '/admin/plugin/questionnaire',
      label: $t('前往问卷管理'),
      icon: 'Backstage',
    })
  }

  return [
    {
      name: PluginNameEnum.Questionnaire,
      path: '/user/questionnaire',
      label: $t('问卷'),
      type: MenuType.Apps,
      subMenus,
    },
  ]
})
