import { compact, flatten, map } from '@seiue/util'

import { TermTypeEnum } from 'packages/features/terms/types'
import { FieldGroup } from 'packages/sdks-next/chalk'

/**
 * 从给定的字段组数组中提取所有的 termType 字段
 *
 * @param groups - 字段组
 * @returns 返回一个对象，其键是字段名，值是对应的 termType。如果字段没有 termType，则不会包含在返回的对象中
 */
export const extractAllTermTypeFieldsFromGroups = (
  groups: FieldGroup[],
): Record<string, TermTypeEnum> => {
  const fields = compact(flatten(map(groups, 'fields')))

  return fields.reduce((acc, cur) => {
    if (!cur.termType) return acc
    return {
      ...acc,
      [cur.name]: cur.termType,
    }
  }, {})
}
