import { lazy } from 'react'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { ModalRouteNode } from 'packages/route'
import { PluginCategoryEnum } from 'packages/sdks-next/chalk'

export const routes = [
  {
    path: 'admin/plugin/copilot/stats',
    name: 'AdminCopilotStats',
    component: lazy(() =>
      import('../pages/Admin/Stats').then(m => ({
        default: m.CopilotAdminStats,
      })),
    ),
    getTitle: () => $t('数据统计'),
    module: [PluginCategoryEnum.通用工具, PluginNameEnum.Copilot],
  },
  {
    path: 'admin/plugin/copilot/knowledge-bases',
    name: 'AdminCopilotKnowledgeBases',
    component: lazy(() =>
      import('../pages/Admin/KnowledgeBases').then(m => ({
        default: m.KnowledgeBaseList,
      })),
    ),
    getTitle: () => $t('知识库'),
    module: [PluginCategoryEnum.通用工具, PluginNameEnum.Copilot],
  },
]

export const modalRoutes: ModalRouteNode[] = [
  {
    name: 'PluginCopilotKnowledgeBaseDetailModal',
    getTitle: () => $t('知识库详情'),
    component: lazy(() =>
      import('../pages/Admin/KnowledgeBaseDetailModal').then(m => ({
        default: m.KnowledgeBaseDetailModal,
      })),
    ),
  },
]
