export interface UploadLimitSettingInterface {
  /**
   * 限制范围，共享网盘可以传递shared固定值
   */
  scope: string
  /**
   * 允许上传的类型，逗号分割
   */
  allowed: string
}

export class UploadLimitSetting implements UploadLimitSettingInterface {
  /**
   * 限制范围，共享网盘可以传递shared固定值
   */
  scope: string
  /**
   * 允许上传的类型，逗号分割
   */
  allowed: string

  constructor(props: UploadLimitSettingInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      scope: _scope,
      allowed: _allowed,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.scope = _scope
    this.allowed = _allowed
  }

  static propKeys = ['scope', 'allowed']
}
