/**
 * @file 行政班-宿舍评价记录
 */
import { lazy } from 'react'

import { $t } from 'packages/locale'
import { RouteConfig } from 'packages/route'

export const adminClassDefaultSubRoutes: RouteConfig[] = [
  {
    path: 'dorm-assessment',
    name: 'DormAssessment',
    getTitle: () => $t('宿舍评价'),
    component: lazy(() =>
      import('./adminClassDormTab').then(m => ({
        default: m.AdminClassDormTab,
      })),
    ),
    subRoutes: [
      {
        path: 'statistics',
        name: 'AdminClassDormAssessmentStatistics',
        getTitle: () => $t('宿生统计'),
        component: lazy(() =>
          import(
            '@/plugins/features/dorms/pages/admin/Statistics/List/Member'
          ).then(m => ({
            default: m.Member,
          })),
        ),
      },
      {
        path: 'records',
        name: 'AdminClassDormAssessmentRecords',
        getTitle: () => $t('评价记录'),
        component: lazy(() =>
          import(
            '@/plugins/features/dorms/pages/admin/Statistics/List/AssessmentRecord'
          ).then(m => ({
            default: m.AssessmentRecord,
          })),
        ),
      },
    ],
  },
]
