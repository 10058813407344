/**
 * @file school api 相关 hooks
 */

import { isDevelopment, isProduction, isStaging } from '@seiue/env'
import { isArray } from '@seiue/util'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { useCurrentReflection } from 'packages/features/sessions'
import { useIsEnLocale } from 'packages/locale/hooks'
import { Expand } from 'packages/sdks-next'
import { RoleEnum, School } from 'packages/sdks-next/chalk'

/**
 * Session 初始化阶段可能返回 undefined
 *
 * @returns 当前学校数据
 */
export const useCurrentSchool = ():
  | Expand<School, ['customConstraints']>
  | undefined => useSelector((state: any) => state.session.currentSchool)

/**
 * 获取当前登录身份的学校 id
 *
 * @returns 学校 id
 */
export const useCurrentSchoolId = () => {
  return useCurrentReflection().schoolId
}

/**
 * 检测当前学校是否是某个学校
 *
 * @param id - 学校 id
 * @returns boolean
 */
export const useCheckCurrentSchoolId = (id: number | number[]): boolean => {
  const currentSchool = useCurrentSchool()

  if (isArray(id)) {
    return !!currentSchool?.id && id.includes(currentSchool?.id)
  }

  return currentSchool?.id === id
}

/**
 * 常用的学校判断 Hooks
 */

/**
 * 是否是北大附中
 *
 * @returns boolean
 */
export const useIsBDFZ = () => {
  const isBdfzOfflineTest = useIsOfflineTestSchool()
  const isBdfzOnlineTest = useIsOnlineTestSchool()
  const isBdfzProduct = useCheckCurrentSchoolId(3)
  return isBdfzOfflineTest || isBdfzOnlineTest || isBdfzProduct
}

/**
 * 是否是深圳中学
 *
 * @returns boolean
 */
export const useIsShenzhong = () => useCheckCurrentSchoolId(282)

/**
 * 是否是广州中学
 *
 * @returns boolean
 */
export const useIsGzzx = () => {
  const schooldId = isDevelopment() || isStaging() ? 363 : 145
  return useCheckCurrentSchoolId(schooldId)
}

/**
 * 是否为广中的学生
 *
 * 一些针对广中学生的 hack
 * https://bjseiue.feishu.cn/wiki/MJMDw6slsi5LVVkRBfHclS4onRd
 *
 * @returns boolean
 */
export const useIsGzzxStudent = () => {
  const { role } = useCurrentReflection() ?? {}
  const isGzzx = useIsGzzx()

  return isGzzx && role === RoleEnum.Student
}

/**
 * 是否是线上的测试环境 schoolId: 341
 *
 * @returns boolean
 */
export const useIsOnlineTestSchool = () => {
  const isOnlineTestSchoolId = useCheckCurrentSchoolId(341)
  return isProduction() && isOnlineTestSchoolId
}

/**
 * 是否是线下的测试环境 schoolId: 363、604
 *
 * @returns boolean
 */
export const useIsOfflineTestSchool = () => {
  const isOfflineTestSchoolId363 = useCheckCurrentSchoolId(363)
  const isOfflineTestSchoolId604 = useCheckCurrentSchoolId(604)
  return (
    (isDevelopment() || isStaging()) &&
    (isOfflineTestSchoolId363 || isOfflineTestSchoolId604)
  )
}

/**
 * 是否是北大附中海口学校
 *
 * @returns boolean
 */
export const useIsBdfzHaikou = () => useCheckCurrentSchoolId(199)

/**
 * 是否是北京第一实验
 *
 * @returns boolean
 */
export const useIsBJDYSY = () => useCheckCurrentSchoolId(339)

/**
 * 是否是复旦附中
 *
 * @returns boolean
 */
export const useIsFdfz = () => useCheckCurrentSchoolId(391)

/**
 * 是否是北京第一实验演示学校
 *
 * @returns boolean
 */
export const useIsBjdysyDemo = () => useCheckCurrentSchoolId(423)

/**
 * 是否为北京汇文中学
 *
 * @returns 是否
 */
export const useIsBjhwzx = () => useCheckCurrentSchoolId(340)

/**
 * 是否是深圳湾学校
 *
 * @returns boolean
 */
export const useIsSZW = () => useCheckCurrentSchoolId(142)

/**
 * 是否是深圳湾学校
 *
 * @param schoolId - 学校 id
 * @returns 是否
 */
export const isSzw = (schoolId: number) => schoolId === 142

/**
 * 是否是上海协和培明学校
 *
 * @returns boolean
 */
export const useIsSHXHPM = () => useCheckCurrentSchoolId(403)

/**
 * 是否为厦门市华师希平双语高级中学
 *
 * @returns 是否
 */
export const useIsXmhsxp = () => useCheckCurrentSchoolId(443)

/**
 * 是否为探月学校
 *
 * @returns 是否
 */
export const useIsTy = () => useCheckCurrentSchoolId(352)

/**
 * 是否为北京中学
 *
 * @returns 是否
 */
export const useIsBjzx = () => useCheckCurrentSchoolId(455)

/**
 * 是否为深圳明德实验双语学校
 *
 * @returns 是否
 */
export const useIsMdsysyxx = () => useCheckCurrentSchoolId(408)

/**
 * 是否为厦门市华师希平双语高级中学
 *
 * @returns 是否
 */
export const useIsHsxpsyxx = () => useCheckCurrentSchoolId(443)

/**
 * 是否为天津英华实验学校
 *
 * @returns 是否
 */
export const useIsTjyh = () => useCheckCurrentSchoolId(392)

/**
 * 是否为王府中学
 *
 * @returns 是否
 */
export const useIsWF = () => useCheckCurrentSchoolId(120)

/**
 * 是否为海淀进修学校附属房山实验学校
 *
 * @returns 是否
 */
export const useIsHdjxfs = () => useCheckCurrentSchoolId(467)

/**
 * 是否是人朝分东坝学校
 *
 * @returns 是否
 */
export const useIsRcfdb = () => useCheckCurrentSchoolId(445)

/**
 * 是否是深圳龙腾学校
 *
 * @returns 是否
 */
export const useIsSzlt = () => useCheckCurrentSchoolId(474)

/**
 * 是否是上海市徐汇区上汇实验学校

 * @returns 是否
 */
export const useIsShshsy = () => useCheckCurrentSchoolId(524)

/**
 * 是否是北京师范大学亚太实验学校
 *
 * @returns 是否
 */
export const useIsBSDYTSY = () => useCheckCurrentSchoolId(380)

/**
 * 是否是平谷区农业中关村学校
 *
 * @returns 是否
 */
export const useIsPGNYZGC = () => useCheckCurrentSchoolId(395)

/**
 * 是否为应用商店的测试学校「应用商店学校」
 *
 * @returns 是否
 */
export const useIsAppStoreSchool = () => {
  const currentSchoolId = useCurrentSchoolId()
  return isProduction() && currentSchoolId === 194
}

/**
 * 是否是人朝分东坝学校
 *
 * @param schoolId - 学校 id
 * @returns 是否
 */
export const isRcfdb = (schoolId: number) => schoolId === 445

const getSchoolName = (isEn: boolean, school?: School | null) => {
  if (!school) return ''

  if (isEn) return school.ename || school.pinyin || school.name
  return school.name
}

/**
 * 获取当前学校名称（能根据语言环境返回中文/英文名称）
 *
 * @returns 名称
 */
export const useCurrentSchoolName = () => {
  const currentSchool = useCurrentSchool()
  const isEnLocale = useIsEnLocale()

  return getSchoolName(isEnLocale, currentSchool)
}

/**
 * 返回一个获取当前学校名称的函数
 *
 * @returns 函数
 */
export const useGetSchoolName = () => {
  const isEn = useIsEnLocale()

  return useCallback(
    (school?: School | null) => getSchoolName(isEn, school),
    [isEn],
  )
}

/**
 * 获取本学校是否需要隐藏 usin
 * 某些特殊学校需要隐藏 usin
 * 目前用到的是全国中小学校长论坛，由于该学校 usin 为手机号，故隐藏
 *
 * @returns boolean
 */
export const useIsHideUsinSchool = () => {
  const hideUsinSchoolIds = [355, 362]
  const currentSchool = useCurrentSchool()

  return hideUsinSchoolIds.includes(currentSchool?.id || 0)
}

/**
 * 是否需要隐藏心理测评信息
 *  - 待办标签、待办标签、点击待办弹窗里的左侧标题
 *
 * @returns boolean
 */
export const useIsHidePsyInfoSchool = () => {
  const hideUsinSchoolIds = [338]

  const currentSchool = useCurrentSchool()

  return hideUsinSchoolIds.includes(currentSchool?.id || 0)
}
