/**
 * @file 用户安全字段相关 utils
 */
import humps from 'humps'
import { useMemo } from 'react'

import type { ColumnConfig as DataTableColumnConfig } from 'packages/components/DataTable/types/ColumnConfig'
import { ColumnConfig } from 'packages/components/Table/defaults/types'
import { SecurityGroupNameEnum } from 'packages/feature-utils/fields'
import {
  useCurrentRolesByIsManager,
  useHasCurrentPermission,
} from 'packages/features/roles/hooks'
import {
  PermissionNameEnum,
  RoleEnum,
  reflectionApi$querySecurityGroups,
} from 'packages/sdks-next/chalk'

// 用户管理员的安全字段范围
export const UserManagerSecurityScopes = [
  SecurityGroupNameEnum.Base,
  SecurityGroupNameEnum.Manage,
  SecurityGroupNameEnum.Private,
]

// 一般管理员的安全字段范围
export const CommonManagerSecurityScopes = [
  SecurityGroupNameEnum.Base,
  SecurityGroupNameEnum.Manage,
]

// 一般用户的安全字段范围
export const CommonUserSecurityScopes = [SecurityGroupNameEnum.Base]

interface Props {
  role: RoleEnum
  securityScopes?: SecurityGroupNameEnum[]
  usefulSecurityScopes?: SecurityGroupNameEnum[]
  disable?: boolean
}

/**
 * 获取安全范围内的字段
 *
 * @param param0 - 参数
 * @param param0.role - 身份
 * @param param0.securityScopes - 安全范围定义，默认以当前用户的权限来计算，自定义时请注意其是否超出权限范围
 * @param param0.usefulSecurityScopes - 在限定安全范围下，根据权限返回字段
 * @param param0.disable - 是否禁用
 * @returns - 字段集
 */
export const useSecurityFields = ({
  role,
  securityScopes,
  usefulSecurityScopes,
  disable,
}: Props) => {
  const { data: securityGroups, loading } =
    reflectionApi$querySecurityGroups.useApi(role, {
      disable,
    })

  const isUserManager = useHasCurrentPermission({
    permission: PermissionNameEnum.CoreUserRead,
    isManager: true,
  })

  const isManager = useCurrentRolesByIsManager(true)

  return [
    useMemo(() => {
      if (!securityGroups) return []

      let _securityScopes = securityScopes

      if (!_securityScopes?.length) {
        if (isUserManager) {
          // 如果有用户管理权限，可查看全部级别的字段
          _securityScopes = UserManagerSecurityScopes
        } else if (isManager) {
          // 如果其他管理员身份，可查看基础 + 管理
          _securityScopes = CommonManagerSecurityScopes
        } else {
          // 其余就是基础
          _securityScopes = CommonUserSecurityScopes
        }
      }

      if (usefulSecurityScopes) {
        _securityScopes = _securityScopes.filter(scope =>
          usefulSecurityScopes.includes(scope),
        )
      }

      let fields: string[] = []

      securityGroups.forEach(group => {
        if (_securityScopes?.includes(group.name as SecurityGroupNameEnum)) {
          fields = fields.concat(
            (group.fieldNames || []).map(name => humps.camelize(name)),
          )
        }
      })

      return fields
    }, [
      securityScopes,
      securityGroups,
      isUserManager,
      isManager,
      usefulSecurityScopes,
    ]),
    loading,
  ] as const
}

interface UseSecurityColumns<T extends ColumnConfig | DataTableColumnConfig> {
  (props: {
    role: RoleEnum
    columns: T[]
    columnKeyPrefix?: string
    securityScopes?: SecurityGroupNameEnum[]
    usefulSecurityScopes?: SecurityGroupNameEnum[]
  }): [T[], boolean]
}

/**
 * 根据安全级别来过滤表格定义
 *
 * @param param0 - 参数
 * @param param0.role - 身份
 * @param param0.columns - 表格列定义
 * @param param0.columnKeyPrefix - 可能出现的 columns.key 表格前缀
 * @param param0.securityScopes - 安全范围定义，默认以当前用户的权限来计算，自定义时请注意其是否超出权限范围
 * @param param0.usefulSecurityScopes - 在限定安全范围下，根据权限返回字段
 * @returns 过滤后的表格定义
 */
const useSecurityColumnsOrigin: UseSecurityColumns<ColumnConfig> = ({
  role,
  columns,
  columnKeyPrefix,
  securityScopes,
  usefulSecurityScopes,
}) => {
  const [securityFields, loading] = useSecurityFields({
    role,
    securityScopes,
    usefulSecurityScopes,
  })

  return [
    useMemo(
      () =>
        columns.filter(column => {
          const keyPrefixNew = columnKeyPrefix ? `${columnKeyPrefix}.` : ''
          const columnKey = column.key || column.dataIndex

          if (!columnKey) return false

          return securityFields.includes(columnKey.replace(keyPrefixNew, ''))
        }),
      [columns, securityFields, columnKeyPrefix],
    ),
    loading,
  ]
}

/**
 * 获取指定 role 的非隐私字段
 *
 * @deprecated
 *
 * @param props - 参数
 * @returns 过滤后的表格定义
 */
export const useSecurityColumns: UseSecurityColumns<ColumnConfig> = props =>
  useSecurityColumnsOrigin(props)

/**
 * 为新表格获取指定 role 的非隐私字段
 *
 * @param props - 参数
 * @returns 过滤后的表格定义
 */
export const useSecurityColumnsForDataTable: UseSecurityColumns<
  DataTableColumnConfig
> =
  // @ts-expect-error 新旧 DataTable 之间数据结构不兼容
  props => useSecurityColumnsOrigin(props)
