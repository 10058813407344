import { HandoutAssignmentStatusEnum } from './handout-assignment-status-enum'
import {
  SecuredReflection,
  SecuredReflectionInterface,
} from './extends/secured-reflection'

export interface AssignmentInterface {
  /**
   * 分配学案id
   */
  handoutId: number
  /**
   * 分配学案的目录id
   */
  outlineId: number
  /**
   * 分配用户id
   */
  assigneeId: number
  /**
   * 状态
   */
  status: HandoutAssignmentStatusEnum
  /**
   * 分配用户
   */
  assignee?: SecuredReflectionInterface | null
  /**
   * 最新提交时间
   */
  lastSubmittedTime?: string | null
  /**
   * 总分
   */
  totalScore?: string | null
  /**
   * 答题用时
   */
  duration?: number | null
  /**
   * 关联目录满分
   */
  fullScore?: number | null
  /**
   * 关联学案目录是否是自动批阅
   */
  isAutoReview?: boolean | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string
}

export class Assignment implements AssignmentInterface {
  /**
   * 分配学案id
   */
  handoutId: number
  /**
   * 分配学案的目录id
   */
  outlineId: number
  /**
   * 分配用户id
   */
  assigneeId: number
  /**
   * 状态
   */
  status: HandoutAssignmentStatusEnum
  /**
   * 分配用户
   */
  assignee?: SecuredReflection | null
  /**
   * 最新提交时间
   */
  lastSubmittedTime?: string | null
  /**
   * 总分
   */
  totalScore?: string | null
  /**
   * 答题用时
   */
  duration?: number | null
  /**
   * 关联目录满分
   */
  fullScore?: number | null
  /**
   * 关联学案目录是否是自动批阅
   */
  isAutoReview?: boolean | null
  /**
   * 主键 ID
   */
  id: number
  /**
   * 学校 ID
   */
  schoolId: number
  /**
   * 创建时间
   */
  createdAt: string
  /**
   * 更新时间
   */
  updatedAt: string

  constructor(props: AssignmentInterface) {
    const {
      // 重命名变量以规避与 JS 保留词的冲突, 比如 default, with
      handoutId: _handoutId,
      outlineId: _outlineId,
      assigneeId: _assigneeId,
      status: _status,
      assignee: _assignee,
      lastSubmittedTime: _lastSubmittedTime,
      totalScore: _totalScore,
      duration: _duration,
      fullScore: _fullScore,
      isAutoReview: _isAutoReview,
      id: _id,
      schoolId: _schoolId,
      createdAt: _createdAt,
      updatedAt: _updatedAt,
      // 可能会有额外字段比如自定义字段不在类型定义中
      ...rest
    } = props

    Object.assign(this, rest)

    this.handoutId = _handoutId
    this.outlineId = _outlineId
    this.assigneeId = _assigneeId
    this.status = _status
    this.assignee = _assignee ? new SecuredReflection(_assignee) : _assignee
    this.lastSubmittedTime = _lastSubmittedTime
    this.totalScore = _totalScore
    this.duration = _duration
    this.fullScore = _fullScore
    this.isAutoReview = _isAutoReview
    this.id = _id
    this.schoolId = _schoolId
    this.createdAt = _createdAt
    this.updatedAt = _updatedAt
  }

  static propKeys = [
    'handoutId',
    'outlineId',
    'assigneeId',
    'status',
    'assignee',
    'lastSubmittedTime',
    'totalScore',
    'duration',
    'fullScore',
    'isAutoReview',
    'id',
    'schoolId',
    'createdAt',
    'updatedAt',
  ]
}
