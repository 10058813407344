import { isAxiosError } from '@seiue/axios'
import { Modal } from '@seiue/ui'

import { $t } from 'packages/locale'
import { PluginNameEnum } from 'packages/plugins/types'
import { departmentApi$createOrgFieldGroupsAndFields } from 'packages/sdks-next/chalk'

import { InitPluginFeature } from '@/plugins/types'

import { adminMenus as menus } from './menus'
import { adminRoutes as routes, modalRoutes } from './routes'

export const initOrganizationStructure: InitPluginFeature = {
  name: PluginNameEnum.OrganizationStructure,
  init: () => ({
    setting: {
      type: 'json' as const,
      beforeChecked: async () => {
        let checked = false

        try {
          // 尝试创建「组织架构」字段类别和「部门」字段
          await departmentApi$createOrgFieldGroupsAndFields.api()
          checked = true
        } catch (err) {
          if (isAxiosError(err) && err.response.status === 422) {
            const data = err.response.data as Array<{
              field: 'label' | 'name'
              message: string
            }>

            // 是否有重复的「组织架构」字段类别
            const hasDuplicateCategory = data.some(
              ({ field }) => field === 'name',
            )

            // 是否有重复「部门」字段
            const hasDuplicateField = data.some(
              ({ field }) => field === 'label',
            )

            let confirmAlterField = true
            let confirmAlterCategory = true

            if (hasDuplicateField) {
              confirmAlterField = await Modal.confirmAsync({
                title: $t('「部门」字段替换'),
                content: $t(
                  '插件开启后「部门」将作为组织架构的系统字段存在，原有的重名字段将替换为「部门（原）」',
                ),
              })
            }

            if (hasDuplicateCategory) {
              confirmAlterCategory = await Modal.confirmAsync({
                title: $t('「组织架构」字段类别替换'),
                content: $t(
                  '插件开启后「组织架构」将作为系统字段类别存在，原有的重名字段类别将替换为「组织架构（原）」',
                ),
              })
            }

            // 如果同意修改重复的字段和字段类别，则继续开启插件
            if (confirmAlterCategory && confirmAlterField) {
              await departmentApi$createOrgFieldGroupsAndFields.api({
                isReplace: true,
              })
            }

            checked = confirmAlterCategory && confirmAlterField
          }
        }

        return { checked }
      },
    },
    routes,
    modalRoutes,
    menus: [menus],
    slots: {
      userTableHelper: () =>
        import('./slots/data-slots').then(m => m.userTableHelper),
      adminUserFormHelper: () =>
        import('./slots/data-slots').then(m => m.adminUserFormHelper),
      adminUserFieldManagementCustomGroups: () =>
        import('./slots/adminUserFieldManagementCustomGroups').then(
          m => m.adminUserFieldManagementCustomGroups,
        ),
      adminClassTableHelper: () =>
        import('./slots/data-slots').then(m => m.adminClassTableHelper),
      adminClassFormHelper: () =>
        import('./slots/data-slots').then(m => m.adminClassFormHelper),
      customizedGroupTableHelper: () =>
        import('./slots/data-slots').then(m => m.customizedGroupTableHelper),
      customizedGroupFormHelper: () =>
        import('./slots/data-slots').then(m => m.customizedGroupFormHelper),
    },
  }),
}
