import { lazy } from 'react'

import { $t } from 'packages/locale'

export const routes = [
  {
    path: 'todos',
    name: 'Todos',
    getTitle: () => $t('全部待办'),
    component: lazy(() =>
      import('./pages/List').then(m => ({ default: m.TodosList })),
    ),
  },
]
