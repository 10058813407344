/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { FileInfo, FileInfoInterface } from './file-info'

const buildConfig = createConfigBuilder('chalk')

export const netdiskApi$getFileByHash = {
  config(id: string, options?: AxiosRequestExtraConfig): AxiosRequestConfig {
    return buildConfig(options, 'get', '/netdisk/files/{id}/info', {
      id,
    })
  },

  /**
   * @summary 根据文件hash获取文件信息
   *  - 目前用于移动端阅卷获取图片信息，因为前端的安卓端不好实现获取图片信息，后续前端通过以下方式（https://juejin.cn/post/7128270925493403685）实现后，本方法会废弃
   * @param id 如果是网盘文件，该值为 NetdiskFile.id ；如果为附件文件（如任务附件），该值为 NetdiskFile.hash
   * @param [options]
   * @return AxiosResponsePromise<FileInfo>
   */

  api(
    id: string,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<FileInfo> {
    return axios(netdiskApi$getFileByHash.config(id, options))
      .then(({ data: _data, ...rest }) => {
        const data = _data ? new FileInfo(_data) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<TSelected = FileInfo>(
    id: string,
    queryOptions?: QueryOptionsWithSelect<FileInfo, TSelected>,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<FileInfo, TSelected>(
      netdiskApi$getFileByHash.api,
      'Netdisk.getFileByHash',
      queryOptions,
      id,
    )
  },
}

// @ts-ignore
netdiskApi$getFileByHash.api._name_ = `Netdisk.getFileByHash`
