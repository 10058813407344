/* eslint-disable @typescript-eslint/no-unused-vars */

import {
  axios,
  AxiosResponsePromise,
  AxiosRequestExtraConfig,
  AxiosRequestConfig,
} from '@seiue/axios'
import {
  createConfigBuilder,
  useQueryApi,
  useMutationApi,
  UseQueryApiReturns,
} from '../utils'
import { MutationOptions, QueryOptionsWithSelect, Expand } from '../types'
import { FieldGroup, FieldGroupInterface } from './field-group'
import type { GetFieldGroupsQuery } from './get-field-groups-query'

const buildConfig = createConfigBuilder('chalk')

export const fieldApi$getFieldGroups = {
  config(
    domain: string,
    getFieldGroupsQuery?: GetFieldGroupsQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosRequestConfig {
    return buildConfig(
      options,
      'get',
      '/field/{domain}/groups',
      {
        domain,
      },
      getFieldGroupsQuery,
    )
  },

  /**
   * @summary 查询字段组
   * @param domain domain 领域
   * @param [getFieldGroupsQuery]
   * @param [fieldQuery.name]
   * @param [fieldQuery.nameIn]
   * @param [fieldQuery.expand]
   * @param [options]
   * @return AxiosResponsePromise<FieldGroup[]>
   */

  api<const TQuery extends GetFieldGroupsQuery>(
    domain: string,
    getFieldGroupsQuery?: TQuery,
    options?: AxiosRequestExtraConfig,
  ): AxiosResponsePromise<Expand<FieldGroup, TQuery['expand']>[]> {
    return axios(
      fieldApi$getFieldGroups.config(domain, getFieldGroupsQuery, options),
    )
      .then(({ data: _data, ...rest }) => {
        const data = _data ? _data.map((d: any) => new FieldGroup(d)) : _data
        return { data, ...rest }
      })
      .catch(err => {
        throw err
      }) as any
  },

  useApi<
    const TQuery extends GetFieldGroupsQuery,
    TSelected = Expand<FieldGroup, TQuery['expand']>[],
  >(
    {
      domain,
      query,
    }: {
      domain: string
      query?: TQuery
    },
    queryOptions?: QueryOptionsWithSelect<
      Expand<FieldGroup, TQuery['expand']>[],
      TSelected
    >,
  ): UseQueryApiReturns<TSelected> {
    return useQueryApi<Expand<FieldGroup, TQuery['expand']>[], TSelected>(
      fieldApi$getFieldGroups.api,
      'Field.getFieldGroups',
      queryOptions,
      domain,
      query,
    )
  },
}

// @ts-ignore
fieldApi$getFieldGroups.api._name_ = `Field.getFieldGroups`
