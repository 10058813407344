/**
 * @file 报表中心路由注册
 */

import { MessageDomainEnum } from 'packages/features/messages/types'
import {
  calculateLabelStyle,
  registerDomainMessage,
} from 'packages/features/messages/utils'
import { $t } from 'packages/locale'

registerDomainMessage({
  type: MessageDomainEnum.Reporting,
  getLabel: () => $t('预警'),
  labelStyle: calculateLabelStyle('#FFEBEF', '#B5295F'),
  messages: [
    // 报表中心-预警消息
    {
      type: 'warning_received',
      important: true,
    },
  ],
})

registerDomainMessage({
  type: MessageDomainEnum.ReportingDashboard,
  getLabel: () => $t('报表'),
  labelStyle: calculateLabelStyle('#EEECFF', '#447AE6'),
  messages: [
    // 报表中心-发布
    'report_publish_published',
    // 报表中心-撤销
    'report_publish_revoked',
  ],
})

registerDomainMessage({
  type: MessageDomainEnum.ReportingReport,
  getLabel: () => $t('报告单'),
  labelStyle: calculateLabelStyle('#F7E9FA', '#9E37B4'),
  messages: [
    // 报表中心-发布
    {
      type: 'report_publish_published',
      important: true,
    },
    // 报表中心-撤销
    {
      type: 'report_publish_revoked',
    },
  ],
})
