import React from 'react'

import { $t } from 'packages/locale'
import { RoleEnum } from 'packages/sdks-next/chalk'

import {
  RegisterWidget,
  WidgetKeyEnum,
  WidgetLayoutSize,
  WidgetTypeEnum,
} from '../../widgets/types'

export const widgets: RegisterWidget[] = [
  {
    key: WidgetKeyEnum.HomeTodos,
    label: () => $t('待办'),
    icon: 'Todo',
    type: WidgetTypeEnum.CommonBiz,
    enableRoles: [
      RoleEnum.Student,
      RoleEnum.Teacher,
      RoleEnum.Guardian,
      RoleEnum.Staff,
    ],
    sizes: [WidgetLayoutSize.Large],
    layout: {
      rows: 5,
      isDraggable: true,
    },
    PreviewComponent: React.lazy(() =>
      import('./Todos').then(m => ({
        default: m.TodoListPreview,
      })),
    ),
    RealComponent: React.lazy(() =>
      import('./Todos').then(m => ({
        default: m.TodoList,
      })),
    ),
    defaultDisplay: true,
    defaultSort: 100,
    personalization: true,
  },
  {
    key: WidgetKeyEnum.HomeMessages,
    label: () => $t('通知'),
    icon: 'Notice',
    type: WidgetTypeEnum.CommonBiz,
    enableRoles: [
      RoleEnum.Student,
      RoleEnum.Teacher,
      RoleEnum.Guardian,
      RoleEnum.Staff,
    ],
    sizes: [WidgetLayoutSize.Small],
    layout: {
      rows: 6,
      isDraggable: true,
    },
    PreviewComponent: React.lazy(() =>
      import('./Notices').then(m => ({
        default: m.NoticeListPreview,
      })),
    ),
    RealComponent: React.lazy(() =>
      import('./Notices').then(m => ({
        default: m.NoticeList,
      })),
    ),
    personalization: true,
    defaultDisplay: true,
    defaultSort: 200,
  },
  {
    key: WidgetKeyEnum.HomeCalendar,
    label: () => $t('日程'),
    icon: 'Calendar',
    type: WidgetTypeEnum.CommonBiz,
    enableRoles: [
      RoleEnum.Student,
      RoleEnum.Teacher,
      RoleEnum.Guardian,
      RoleEnum.Staff,
    ],
    sizes: [WidgetLayoutSize.Large],
    layout: {
      rows: 8,
      isDraggable: true,
    },
    PreviewComponent: React.lazy(() =>
      import('./Calendar').then(m => ({
        default: m.CalendarPreview,
      })),
    ),
    RealComponent: React.lazy(() =>
      import('./Calendar').then(m => ({
        default: m.Calendar,
      })),
    ),
    personalization: true,
    defaultDisplay: true,
    defaultSort: 300,
  },
  {
    key: WidgetKeyEnum.HomeTools,
    label: () => $t('快捷入口'),
    icon: 'Grid',
    type: WidgetTypeEnum.CommonBiz,
    enableRoles: [
      RoleEnum.Student,
      RoleEnum.Teacher,
      RoleEnum.Guardian,
      RoleEnum.Staff,
    ],
    sizes: [WidgetLayoutSize.Small, WidgetLayoutSize.Large],
    layout: {
      rows: 5,
      isDraggable: true,
    },
    PreviewComponent: React.lazy(() =>
      import('./PluginsShortcut').then(m => ({
        default: m.PluginsShortcutPreview,
      })),
    ),
    RealComponent: React.lazy(() =>
      import('./PluginsShortcut').then(m => ({
        default: m.PluginsShortcut,
      })),
    ),
    personalization: true,
    defaultDisplay: true,
    defaultSort: 400,
  },
]
