import {
  omitURLQuery,
  parseURLQuery,
  stringifyURLQuery,
  uniq,
} from '@seiue/util'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { useI18nName } from 'packages/feature-utils/reflections'
import { roleToLabel } from 'packages/features/reflections/utils'
import { useLoadCurrentPupil } from 'packages/features/reflections/utils/apis'
import {
  useCurrentSchool,
  useGetSchoolName,
} from 'packages/features/schools/hooks'
import {
  AuthorizeParams,
  SessionState,
} from 'packages/features/sessions/utils/types'
import { TermTypeEnum } from 'packages/features/terms/types'
import { $t } from 'packages/locale'
import { wait } from 'packages/sdks-next'
import {
  Guardian,
  OAuthToken,
  Reflection,
  RoleEnum,
  School,
  Term,
  TodoStatusEnum,
  User,
  acmApi$queryConfigItemsByService,
  messageApi$countMessages,
  receivedMessageApi$findAll,
  reflectionApi$loadGuardian,
  systemApi$loadSchool,
  systemApi$querySchools,
  termApi$queryTerm,
  todoApi$queryExecutorTodos,
  wechatApi$listUserBoundWechats,
} from 'packages/sdks-next/chalk'
import { v3RoleToV2 } from 'packages/utils/user'

/**
 * Only refresh if the access token is dying.
 * Export for testing purposes.
 *
 * @param expiresInSeconds - 过期时间
 * @param fetchedAt - 获取时间
 * @returns boolean
 */
export function isTokenDying(expiresInSeconds: number, fetchedAt: number) {
  const now = new Date()
  const ageInSeconds = (now.getTime() - fetchedAt) / 1000
  return expiresInSeconds - ageInSeconds < 600
}

/**
 * 获取当前身份，仅在用户登录后可以使用。
 *
 * @returns 当前身份
 */
export const useCurrentReflection = () =>
  useSelector(
    (state: { session: { currentReflection: Reflection } }) =>
      /**
       * 为了方便工程师使用该 Hook，我们此处断言 currentReflection 一定有值。
       * 但在一些情况下，currentReflection 会变为空（比如在使用 currentReflection 退出登录时，reflection 会被置空，但页面尚未被完全释放，就会导致空指针错误）。
       * 所以，我们在这里放置一个不存在的用户，用于阻止向用户报出空指针错误的问题。
       *
       * 如果在返回了空的身份信息后，用户仍继续使用系统（实际上影响很小，因为身份验证是通过 Token 而非 Reflection），那么届时等待用户自行上报。
       * TODO：可能增加一个检测机制
       */
      state.session.currentReflection || {
        id: wait(0),
        name: 'null',
        schoolId: 0,
        pinyin: 'null',
        account: '',
        usin: 'null',
        role: RoleEnum.Student,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      },
  )

/**
 * 获取用户当前 Session
 *
 * @returns 当前 Session
 */
export const useCurrentSession = () =>
  useSelector((state: { session: SessionState }) => state.session)

/**
 * 该用户是不是当前用户
 *
 * @param rid - 用户 id
 * @returns boolean
 */
export const useIsCurrentReflection = (rid: number) => {
  const { id } = useCurrentReflection()
  return id === rid
}

/**
 * 返回该用户是不是当前用户的 fn
 *
 * @returns fn
 */
export const useIsCurrentReflectionFn = () => {
  const { id } = useCurrentReflection()
  return (rid: number) => id === rid
}

/**
 * 当前用户是不是某人的家长
 *
 * @param rid - 某人 id
 * @returns 是否
 */
export const useIsCurrentGuardian = (rid: number) => {
  const { pupilId } = useCurrentReflection()
  return pupilId === rid
}

/**
 * 返回判断当前用户是不是某人家长的 fn
 *
 * @returns fn
 */
export const useIsCurrentGuardianFn = () => {
  const { pupilId } = useCurrentReflection()
  return (rid: number) => pupilId === rid
}

/**
 * 从当前登录用户中获取操作人的 rid
 *
 * 家长登录：返回其孩子 rid
 * 其他身份登录：返回其自身 rid
 *
 * @returns rid
 */
export const useActionRid = () => {
  const currentReflection = useCurrentReflection()
  const isGuardian = currentReflection.role === RoleEnum.Guardian

  const [pupil, loadingPupil] = useLoadCurrentPupil({
    disable: !isGuardian,
  })

  const rid = isGuardian ? pupil?.id : currentReflection.id

  return {
    rid,
    loading: loadingPupil,
    isGuardian,
  }
}

/**
 * select currentUser from session state with sdks-next type
 *
 * @returns user
 */
export const useCurrentUser = () =>
  useSelector(
    (state: {
      session: {
        currentUser?: User & {
          // FIXME 这里手动变更了 User 的类型，因为 Session 及相关依赖需要 reflection 为 Reflection 类型
          reflections: Reflection[]
        }
      }
    }) => state.session.currentUser,
  )

/**
 * 获取指定身份的全部数据
 *  - 目前为学校名称，归档名称以及家长孩子名称
 *  - 如果含跨学校用户资源，将无法显示跨学校家长的孩子名称
 *
 * @param reflections - 用户信息
 * @returns 返回传入 reflections 的完整信息
 */
export const useReflectionsWithFullData = (reflections: Reflection[]) => {
  const $name = useI18nName()

  const ids = uniq(reflections.map(reflection => reflection.schoolId))
  const currentSchool = useCurrentSchool()

  const options = {
    staleTime: 2 * 60,
    omitAuthorizationHeader: true,
    omitReflectionHeaders: true,
  }

  const { data: querySchoolData } = systemApi$querySchools.useApi(
    {
      idIn: ids.join(','),
      hiddenIn: '0,1',
      paginated: 0,
    },
    { ...options, disable: ids.length <= 1 },
  )

  const { data: loadSchoolData } = systemApi$loadSchool.useApi(
    {
      id: `${ids[0]}`,
    },
    {
      ...options,
      disable: ids.length !== 1 || currentSchool?.id === ids[0],
    },
  )

  const schools: School[] = useMemo(() => {
    if (querySchoolData) {
      return querySchoolData
    }

    if (loadSchoolData) {
      return [loadSchoolData]
    }

    if (currentSchool) {
      return [currentSchool]
    }

    return []
  }, [querySchoolData, loadSchoolData, currentSchool])

  const getSchoolName = useGetSchoolName()

  const [pupilNameMap, setPupilNameMap] = useState<{
    [key: string]: string | undefined
  }>({})

  useEffect(() => {
    // 目前系统允许一个账号绑定多个学校的用户，这导致下方获取家长信息时，获取其它学校的家长信息时会出现资源不存在的错误，目前对请求数据进行学校过滤处理，避免频繁出现“资源不存在”错误
    const guardians = reflections.filter(
      r => r.role === RoleEnum.Guardian && r.schoolId === currentSchool?.id,
    )

    if (guardians.length) {
      /*
       * FIXME
       * 等待 TS4.2
       */
      Promise.all([
        ...guardians.map(guardian =>
          reflectionApi$loadGuardian.api(guardian.id || 0, {
            tryExpand: ['pupil'] as const,
          }),
        ),
        termApi$queryTerm.api(TermTypeEnum.GuardianRole),
      ] as any).then((res: any[]) => {
        const guardianRoleTerms: Term[] = res.pop().data
        const guardiansData: Guardian[] = res.map(_r => _r.data)
        setPupilNameMap(
          guardiansData.reduce(
            (result, data) => ({
              ...result,
              [data.id]: data.pupil
                ? `${$name(data.pupil)} ${
                    guardianRoleTerms.find(
                      term => term.id === data.guardianRoleId,
                    )?.name || $t('家长')
                  }`
                : '',
            }),
            {} as { [key: string]: string | undefined },
          ),
        )
      })
    }
  }, [$name, reflections, currentSchool])

  return useMemo(
    () =>
      reflections.map(reflection => {
        const school = schools?.find(s => s.id === reflection.schoolId)
        let archivedTypeName = reflection.archivedType?.name
        if (archivedTypeName && reflection.role === RoleEnum.Guardian) {
          archivedTypeName = `${$t('学生')}${archivedTypeName}`
        }

        return {
          ...reflection,
          schoolName: getSchoolName(school) || $t('未知学校'),
          archivedTypeName,
          pupilName: pupilNameMap[reflection.id],
        }
      }),
    [reflections, schools, getSchoolName, pupilNameMap],
  )
}

/**
 * 获取当前 User 的全部数据
 *
 * 目前为学校名称，归档名称以及家长孩子名称
 *
 * @returns 当前 User 的全部数据
 */
export const useCurrentUserReflectionsWithFullData = () => {
  const { reflections } = useCurrentUser() || {
    reflections: [] as Reflection[],
  }

  return useReflectionsWithFullData(reflections)
}

/**
 * 返回当前身份的所有数据
 *
 * @returns 当前身份的数据
 */
export const useCurrentReflectionWithFullData = () => {
  const reflections = useCurrentUserReflectionsWithFullData()
  const reflection = useCurrentReflection()

  return reflections.find(({ id }) => reflection.id === id)
}

export type FullDataReflection = ReturnType<
  typeof useCurrentUserReflectionsWithFullData
>[0]

/**
 * 将含有全部数据的身份表达为可读的 string
 *
 * @param reflection - 用户数据
 * @returns 字符串
 */
export const convertFullDataReflectionToString = (
  reflection: FullDataReflection,
) =>
  `${$t('{schoolName}的{roleName}{name}', {
    schoolName: reflection.schoolName,
    roleName: roleToLabel.get(reflection.role),
    name: reflection.name,
  })}${
    reflection.role === RoleEnum.Guardian
      ? `（${reflection.pupilName || ''}）`
      : ''
  }`

/**
 * 从 url 中获取腾讯登录参数
 *
 * @returns 登录参数
 */
export const getTencentAuthParamsFromUrl = (): AuthorizeParams | null => {
  const { code, appid } = parseURLQuery(window.location.search)

  return code && appid
    ? {
        type: 'tencent' as const,
        code: String(code),
        appid: String(appid),
      }
    : null
}

/**
 * 从 url 中获取 token 信息
 *
 * @returns token 信息
 */
export const getTokenAuthParamsFromUrl = (): AuthorizeParams | null => {
  const { accessToken, expiresIn, tokenType, refreshToken } = parseURLQuery(
    window.location.search,
  )

  return accessToken && expiresIn && tokenType
    ? {
        type: 'token' as const,
        oAuthToken: {
          accessToken: String(accessToken),
          refreshToken: refreshToken ? String(refreshToken) : undefined,
          tokenType: String(tokenType),
          expiresIn: Number(expiresIn),
        },
      }
    : null
}

/**
 * 从 url 移除 token 信息
 */
export const removeTokenAuthParamsInUrl = (): void => {
  // 在某些没有 location 的环境（比如 App），忽略该操作
  if (!window.location) return

  const nextUrl = omitURLQuery(window.location.href, [
    'accessToken',
    'expiresIn',
    'tokenType',
    'refreshToken',
  ])

  window.history.replaceState(null, '', nextUrl)
}

/**
 * 创建带有 token 信息的 url query
 *
 * @param oAuthToken - token 信息
 * @param oAuthTokenFetchedAt - token 于何时获取
 * @param targetReflectionId - token 对应的 rid
 * @returns url query
 */
export const createTokenAuthUrlQuery = (
  oAuthToken: OAuthToken,
  oAuthTokenFetchedAt: number,
  targetReflectionId?: number,
) =>
  stringifyURLQuery({
    rid: targetReflectionId,
    ...oAuthToken,
    expiresIn: Math.floor(
      oAuthToken.expiresIn - (Date.now() - oAuthTokenFetchedAt) / 1000,
    ),
  })

/**
 * 创建带有 token 信息的 url query（chalk 2）
 *
 * @param param0 - 参数
 * @param param0.oAuthToken - token 信息
 * @param param0.currentUser - 当前用户
 * @param param0.currentReflection - 当前身份
 * @returns url query
 */
export const createChalk2TokenAuthUrlQuery = ({
  oAuthToken,
  currentUser,
  currentReflection,
}: {
  oAuthToken: OAuthToken
  currentUser: User
  currentReflection: Reflection
}) =>
  stringifyURLQuery({
    access_token: oAuthToken.accessToken,
    expires_in: oAuthToken.expiresIn,
    token_type: oAuthToken.tokenType,
    reflection_id: currentReflection.id,
    user_id: currentUser.id,
    role_id: v3RoleToV2(currentReflection.role),
  })

interface ReflectionUnreadMap {
  [rid: number]: boolean
}

/**
 * 为 Reflection 填充已读状态
 *
 * @returns 消息/待办/通知是否已读状态
 */
export const useReflectionsUnreadStatus = () => {
  const { reflections } = useCurrentUser() || {
    reflections: [] as Reflection[],
  }

  const [rUnreadMap, setRUnreadMap] = useState<ReflectionUnreadMap>({})

  useEffect(() => {
    setRUnreadMap({})
    if (!reflections?.length) return

    const result: ReflectionUnreadMap = reflections.reduce(
      (_result, r) => ({
        ..._result,
        [r.id]: false,
      }),
      {} as ReflectionUnreadMap,
    )

    const setResult = (rid: number, v: boolean) => {
      if (!result[rid]) {
        result[rid] = v
      }
    }

    // 未读消息
    Promise.allSettled(
      reflections.map(reflection =>
        messageApi$countMessages
          .api('readed', {
            type: 'message',
            'owner.id': reflection.id,
            readed: false,
          })
          .then(({ data }) => {
            setResult(reflection.id, !!data?.[0]?.count)
          }),
      ),
    )
      .then(async () => {
        // 未读通知
        await Promise.allSettled(
          reflections.map(reflection => {
            if (result[reflection.id]) return Promise.resolve()

            return receivedMessageApi$findAll
              .api({
                readed: false,
                perPage: 0,
                notice: true,
                'owner.id': reflection.id,
              })
              .then(({ pagination }) => {
                setResult(reflection.id, !!pagination?.totalCount)
              })
          }),
        )
      })
      .then(async () => {
        // 未处理待办
        await Promise.allSettled(
          reflections.map(reflection => {
            if (result[reflection.id]) return Promise.resolve()

            return todoApi$queryExecutorTodos
              .api(reflection.id, {
                status: TodoStatusEnum.Pending,
                perPage: 1,
              })
              .then(({ pagination }) => {
                setResult(reflection.id, !!pagination?.totalCount)
              })
          }),
        )

        setRUnreadMap(result)
      })
  }, [reflections])

  return rUnreadMap
}

/**
 * 获取当前学校绑定的微信公众号
 *
 * @param param0 - 参数
 * @param param0.disable - 是否禁用
 * @returns 微信公众号
 */
export const useWechatProviders = ({ disable }: { disable?: boolean } = {}) => {
  const currentSchoolId = useCurrentReflection().schoolId

  const res = acmApi$queryConfigItemsByService.useApi(
    {
      service: 'seiue.chalk',
      scope: `school.${currentSchoolId}`,
      query: {
        groupIn: 'wechat.global',
        nameIn: 'official_account_provider',
      },
    },
    {
      disable,
      staleTime: 5 * 60,
    },
  )

  return { ...res, data: res.data?.[0].value as string | null }
}

/**
 * 获取当前用户的微信绑定数据
 *
 * @returns 数据
 */
export const useCurrentBoundWechats = () => {
  const user = useCurrentUser()
  const { data: wechatProvider, loading: loadingWechatProvider } =
    useWechatProviders()

  const loadingProvider = loadingWechatProvider
  const hasProvider = !!wechatProvider

  const { data, loading, reload } = wechatApi$listUserBoundWechats.useApi(
    {
      userId: user?.id ?? 0,
      query: {
        provider: 'all',
      },
    },
    { disable: !user || loadingProvider || !hasProvider },
  )

  return {
    wechats: data?.filter(w => !w.isWework),
    loading,
    reload,
  }
}
