import {
  genderToLabel,
  getReflectionAdminClassesLabels,
  getReflectionGenderLabel,
  getReflectionGraduatesInAndGradeLabel,
} from 'packages/features/reflections/utils/views'

import {
  SecuredReflection as Base,
  SecuredReflectionInterface,
} from '../secured-reflection'

export type { SecuredReflectionInterface }

/**
 * 身份
 */
export class SecuredReflection extends Base {
  /**
   * 带年级的行政班列表
   *
   * @returns 行政班列表
   */
  get adminClassesLabels() {
    return getReflectionAdminClassesLabels(this)
  }

  /**
   * 带年级的届别
   *
   * @returns 届别
   */
  get graduatesInAndGradeLabel() {
    return getReflectionGraduatesInAndGradeLabel(this)
  }

  /**
   * 性别 label
   *
   * @returns 性别 label
   */
  get genderLabel() {
    return getReflectionGenderLabel(this)
  }

  /**
   * 获取 gender (性别) 的所有选项
   *
   * @param sourceKey - 原始值在选项 object 中的 key, 默认 value
   * @param mappedKey - 文字标签在选项 object 中的 key, 默认 label
   * @returns 选项数组, 默认 [{ value, label }]
   */
  static getGenderOptions<S extends string, M extends string>(
    sourceKey: S,
    mappedKey: M,
  ) {
    return genderToLabel.listOptions(sourceKey, mappedKey)
  }
}
