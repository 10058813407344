/**
 * @file group 下的类型补充
 */

export enum GroupSettingEnum {
  GoalBasic = 'goal.basic',
  TaskSetting = 'task.settings',
  DirectionGlobal = 'direction.global',
  CommonSetting = 'common.setting',
  VnasEvaluationStatsDimensions = 'vnas.evaluation.stats_dimensions',
  ReflectionStudentSetting = 'reflection.student.setting',
  ReflectionTeacherSetting = 'reflection.teacher.setting',
  ReflectionGuardianSetting = 'reflection.guardian.setting',
  ReflectionStaffSetting = 'reflection.staff.setting',
  ReflectionPasswordSetting = 'reflection.password_settings',

  AdminClassConfig = 'admin_class.config',
  AcademicGoalMilestone = 'academic-goal.milestone',
  // 课表查询 - 个性化设置
  PluginClassSchedule = 'plugin.class_schedule',
  // 校历管理 - 设置
  SchoolCalendar = 'schcal.settings',
  // classIn 管理 - 设置
  ClassInOnlineClass = 'online_class.settings',
  // lms 插件设置
  LMSSetting = 'lms.settings',
  // 学分管理 - 设置
  AwardedCreditSetting = 'awarded_credit.setting',
  // 课程库管理 - 设置
  CourseSetting = 'course.settings',
  // 课程班管理 - 设置
  ClassSetting = 'class.settings',
  // 宿生列表树状筛选
  DormMemberFilter = 'dorm.global',
  // 认证积分设置
  CertSettings = 'cert_settings',
  // 报表配置
  ReportSettings = 'reporting.settings',
  // 出入校管理 - 物联网配置
  InsiderSettings = 'insider_settings',
  // 考试管理 - 全局设置 - 成绩分析维度配置
  GradeStatsSettings = 'grade_stats.settings',
  // 调代课管理 - 设置
  ClassAdjustmentSetting = 'class.adjustment',
}

export enum GroupPermissionTypeEnum {
  PersonalBase = 'personal.base',
  PersonalManage = 'personal.manage',
  PersonalPrivacy = 'personal.private',
  GoalDirection = 'goal.direction',
  GoalSubjectDirection = 'goal.subject_direction',
  GoalGoal = 'goal.goal',
  GoalAcademicGoal = 'goal.academic_goal',
  GoalIntlGoal = 'goal.intl_goal',
  GoalCredit = 'goal.credit',
  GoalCert = 'goal.cert',
  RecordFeedback = 'record.feedback',
  RecordTask = 'record.task',
  RecordPlace = 'record.place',
  RecordActivity = 'record.activity',
  RecentCalendar = 'recent.calendar',
  RecentAttendance = 'recent.attendance',
  RecentAbsence = 'recent.absence',
  RecentChat = 'recent.chat',
  ScoreScore = 'score.score',
  ScoreExam = 'score.exam',
  GroupClass = 'group.class',
  GroupDorm = 'group.dorm',
  GroupAdminClass = 'group.admin_class',
  CertificationCertification = 'certification.certification',
  GradeReportExamReport = 'grade_report.exam_report',
  GradeReportSemesterReport = 'grade_report.semester_report',
  GradeReportGlobalAcademicReport = 'grade_report.global_academic_report',
  GradeReportChuguoReport = 'grade_report.chuguo_report',
  GradeReportResitReport = 'grade_report.resit_report',
  CgrExamReport = 'cgr.exam_report',
  CgrGzzxHistoryExamReport = 'cgr.gzzx_history_exam_report',
  CgrGzzxAssessmentExamReport = 'cgr.gzzx_assessment_exam_report',
  CgrGzzxExamReport = 'cgr.gzzx_exam_report',
  CgrSzwSemesterReport = 'cgr.szw_semester_report',
  CgrSzczAbroadReport = 'cgr.szcz_abroad_report',
  CgrSzczResitReport = 'cgr.szcz_resit_report',
  QuestionnaireUncompletedNoticeForReflection = 'questionnaire.uncompleted_notice_for_reflection',
  MoralScoreStats = 'moral.score_stats',
  PersonalHome = 'personal.home',
  StudentStatusCity = 'student_status.city',
  StudentStatusCountry = 'student_status.country',
  CreditCredit = 'credit.credit',
  AccomplishmentAll = 'accomplishment.all',
  ContractContract = 'contract.contract',
  ReportCenter = 'report_center',
  SemesterReview = 'semester_review.semester_review',
}

export enum GroupRoleEnum {
  Admin = 'admin',
  AdminClassTeacher = 'admin_class_teacher',
  DormManager = 'dorm_manager',
  Mentor = 'mentor',
  Teacher = 'teacher',
  Guardian = 'guardian',
}

/**
 * Group 所对应的作用域，取值为：
 * - 行政班（adminclass）
 * - 宿舍（dorm）
 * - 课程（course）
 * - 档案群组（relation）
 * - 课程班（格式为 class.semester-<semester_id>，因为是动态值，我们在此简化为 class）
 */
export enum GroupScopeEnum {
  AdminClass = 'adminclass',
  Dorm = 'dorm',
  Course = 'course',
  Relation = 'relation',
  Class = 'class',
}
